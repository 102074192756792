import React, { memo, useCallback, useEffect, useMemo } from "react";
// import { MdFilterAlt } from "react-icons/md";
import { FeedbackCard } from "../../components/Cards";
import { Loader } from "../../../../components";
import { Pagination, Paper, Stack, Typography } from "@mui/material";
import { useSearch } from "../../context/SearchContext";
import "./MobileFeedbackListing.scss";

const Feedback = ({
  feedbacksData,
  handlePageChange,
  pageCount,
  pageNumber,
  loading,
  handleSearchResults,
}) => {
  const { searchQuery } = useSearch();

  const memoizedData = useMemo(() => feedbacksData, [feedbacksData]);

  const handleSearchResultCallback = useCallback(() => {
    handleSearchResults(searchQuery);
  }, [handleSearchResults, searchQuery]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearchResultCallback();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearchResultCallback, searchQuery]);

  return (
    <Paper elevation={0} className={"eLearningMobileFeedbackRoot"}>
      {/* <div className={"filtersContainer"}>
        <div className={"filterIcon"}>
          <MdFilterAlt />
        </div>
        <Typography className={"filterText"}>Filters</Typography>
      </div> */}
      {loading ? (
        <div className={"eLearningMobileFeedbackLoaderContainer"}>
          <Loader />
        </div>
      ) : memoizedData.length > 0 && !loading ? (
        <div className={"eLearningMobileFeedbackContentContainer"}>
          {memoizedData.map((item, index) => (
            <FeedbackCard
              key={item._id}
              id={item._id}
              serialNumber={index + 1}
              date={item.updatedAt}
              description={item.feedback}
              lesson={item?.lesson_id?.lesson_name}
              slide={item?.slide_id?.slide_title}
              status={item.status}
            />
          ))}
        </div>
      ) : (
        <div className={"eLearningMobileFeedbackText"}>No Data Found</div>
      )}

      <div className={"eLearningMobileFeedbackPaginationContainer"}>
        <Stack spacing={2} className={"eLearningMobileFeedbackPaginationStack"}>
          <Pagination
            size="small"
            color="primary"
            count={pageCount}
            variant="outlined"
            page={pageNumber}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper>
  );
};

export default memo(Feedback);
