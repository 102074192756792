import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";
import { MdOutlineDashboard } from "react-icons/md";
import { ProgressBar } from "../../../../utilities/v5/common";
import { Search, MobileLinearLoader } from "../../utilities";
import { wave } from "../../../../assets/images/mobileView/icons";
import { mobileTheme } from "../../constant";
import { history } from "../../../../history";
import { getMyLessonsCount } from "../../../../pages/eLearning/MyCourses/modules/actions";
import { useSearch } from "../../context/SearchContext";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import axios from "axios";
import { MY_TOKEN } from "../../../../config";

import "./eLearningMobileTopNav.scss";

function ElearningTopNav(props) {
  const location = useLocation();
  const { searchQuery, updateSearchQuery } = useSearch();

  const [feedbackCount, setFeedbackCount] = useState();

  const fetchCourseFeedback = useCallback(async (data) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/feedback`,
        {
          headers: { Authorization: MY_TOKEN },
          params: data,
        }
      )
      .then((response) => {
        if (response.data.success) {
          setFeedbackCount(response.data.count);
        }
      });
  }, []);

  useEffect(() => {
    if (location.pathname === "/elearning/feedbacks") {
      let data = { page: 1, limit: 5 };
      fetchCourseFeedback(data);
    }

    if (location.pathname === "/elearning/mycourses") {
      props.getMyLessonsCount();
    }
  }, [location.pathname]);

  const handleSearchChange = useCallback(
    (e) => {
      updateSearchQuery(e.target.value);
    },
    [updateSearchQuery]
  );

  return /^\/elearning\/feedbacks\/\w+$/.test(location.pathname) ? (
    <div className={"eLearningMobileTopNavFeedbackDetailsRoot"}>
      <div className={"eLearningMobileTopNavFeedbackDetailContainer"}>
        <IconButton
          onClick={() => history.push("/elearning/feedbacks")}
          sx={{ "& .MuiButtonBase-root": { width: "30px", height: "30px" } }}
          style={{ width: "30px", height: "30px" }}
        >
          <MdOutlineArrowBackIosNew
            className={"eLearningMobileTopNavBackButton"}
          />
        </IconButton>
        <Typography
          variant="subtitle1"
          className={"eLearningMobileTopNavFeedbackDetailTitle"}
        >
          Feedback Detail
        </Typography>
      </div>
    </div>
  ) : (
    <AppBar
      className={"eLearningMobileTopNavMobileAppBar"}
      style={{
        height: location.pathname === "/elearning/grading" ? "56px" : "120px",
        background: mobileTheme,
      }}
    >
      <Toolbar
        className={"eLearningMobileTopNavToolbarContainer"}
        sx={{ "& MuiToolbar-root": { width: "100%" } }}
      >
        <div className={"eLearningMobileTopNavUserInfo"}>
          <img
            alt="waving hand"
            src={wave}
            className={"eLearningMobileTopNavWavingHandIcon"}
          />
          <Typography
            variant="subtitle1"
            className={"eLearningMobileTopNavGreetingsTypography"}
          >
            Hi {props.userName}
          </Typography>
        </div>
        <div className={"eLearningMobileTopNavDashboardIconView"}>
          <Link to="/dashboard">
            <MdOutlineDashboard
              className={"eLearningMobileTopNavDashboardIcon"}
            />
          </Link>
        </div>
      </Toolbar>

      {location.pathname === "/elearning/mycourses" ? (
        <Box className={"eLearningMobileTopNavCourseInfoContainer"}>
          {!props.courseLessonCountLoading && props.courseLessonDataCount ? (
            <>
              <Typography
                variant="body2"
                className={"eLearningMobileTopNavCourseInfoTypography"}
              >
                You have completed{" "}
                {props.courseLessonDataCount?.completed_lessons}/
                {props.courseLessonDataCount?.total_lessons}
              </Typography>
              <Box className={"eLearningMobileTopNavProgressBarContainer"}>
                <ProgressBar
                  themeColor={"#23C949"}
                  progressValue={
                    (props.courseLessonDataCount?.completed_lessons /
                      props.courseLessonDataCount?.total_lessons) *
                    100
                  }
                  height={8}
                  fontSize={"7px"}
                  percentageFlag={true}
                />
              </Box>
            </>
          ) : (
            <Box className={"eLearningMobileTopNavProgressBarContainer"}>
              <MobileLinearLoader />
            </Box>
          )}
        </Box>
      ) : location.pathname === "/elearning/myhelpdesk" ? (
        <Toolbar className={"eLearningMobileTopNavSearchToolbar"}>
          <Search
            value={searchQuery}
            handleSearch={handleSearchChange}
            placeholder={"Search your topic"}
          />
        </Toolbar>
      ) : (
        location.pathname === "/elearning/feedbacks" && (
          <Box className={"eLearningMobileTopNavFeedbacksContainer"}>
            <Typography
              variant="body2"
              className={"eLearningMobileTopNavFeedbacksTypography"}
            >
              You have submitted {feedbackCount} Feedbacks
            </Typography>
            <Search
              value={searchQuery}
              handleSearch={handleSearchChange}
              placeholder={"Search your topic"}
            />
          </Box>
        )
      )}
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  courseLessonCountLoading: state.eLearningMyCourses.courseLessonCountLoading,
  courseLessonDataCount: state.eLearningMyCourses.courseLessonDataCount,
});

const ConnectWith = connect(mapStateToProps, {
  getMyLessonsCount,
})(ElearningTopNav);

export default ConnectWith;
