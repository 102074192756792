import { useState } from "react";

import { LessonTab } from "../../utilities";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import Carousel from "react-simply-carousel";
import { withStyles } from "@material-ui/core";

const styles = {
  lessonSlidesBoxRoot: {
    boxShadow: "0px -4px 3px #00000026",
    width: "100%",
    margin: "auto",
    padding: "8px 4px 8px 4px",
    overflow: "hidden",
    background: "white"
  },
  lessonSlidesBoxArrowIcon: {
    fontSize: "25px"
  },
};

const SlidesBox = ({
  slides,
  handleSlide,
  slideIndex,
  slideAccessFlag,
  classes,
}) => {
  const [activeSlide, setActiveSlide] = useState(slideIndex);

  const handleActiveSlide = (val) => {
    setActiveSlide(val);
  };

  return (
    <article className={classes.lessonSlidesBoxRoot}>
      <Carousel
        updateOnItemClick
        containerProps={{
          style: {
            width: "100%",
            justifyContent: "center",
            left: "100%",
          },
        }}
        className="slidescroll"
        activeSlideIndex={activeSlide}
        onRequestChange={(val) => handleActiveSlide(val)}
        forwardBtnProps={{
          children: (
            <IoMdArrowDropright className={classes.lessonSlidesBoxArrowIcon} />
          ),
          style: {
            width: 30,
            alignSelf: "center",
            backgroundColor: "#fff",
            border: "0px solid white"
          }
        }}
        backwardBtnProps={{
          children: (
            <IoMdArrowDropleft className={classes.lessonSlidesBoxArrowIcon} />
          ),
          style: {
            width: 30,
            alignSelf: "center",
            backgroundColor: "#fff",
            border: "0px solid white",
          },
        }}
        itemsToScroll={1}
        speed={100}
        infinite={false}
      >
        {slides
          ? slides?.map((slide, index) => (
              <LessonTab
                key={index}
                index={index}
                handleSlide={handleSlide}
                slideAccessFlag={slideAccessFlag}
                data={slide?.lesson_type === 1 && slide?.content}
                id={index + 1}
                lesson_type={
                  slide?.lesson_type === 1
                    ? 1
                    : slide?.lesson_type === 2
                    ? 2
                    : slide?.lesson_type === 3 && 3
                }
                selected={slideIndex}
                completedFlag={
                  slideIndex === index && slide?.isCompleted
                    ? true
                    : slide?.isCompleted
                    ? true
                    : false
                }
                slideAccess={
                  slides[index - 1]?.isCompleted ||
                  slide?.isCompleted ||
                  slides.length === 1
                    ? ""
                    : "none"
                }
              />
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
              <LessonTab
                key={index}
                data={
                  " Graphic design is a craft where profe ssionals create visual content to communicate messages. What is graphics Applications of Graphics Graphic design can serve many functions. Just a few of the uses of graphic design include: Corporate identity/branding Corporate identity/branding Corporate identity/branding Corporate identity/branding"
                }
                id={index}
                lesson_type={index % 2 !== 0 ? 1 : 2}
                selected={1}
              />
            ))}
      </Carousel>
    </article>
  );
};

export default withStyles(styles)(SlidesBox);
