import { MoNGO_DB_API_URL, HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import axios from "axios";

export const lessonServices = {
  fetchAllLessons,
  fetchLessonByID,
  addData,
  editData,
  deleteData,
  fetchQuestion,
  addQuestion,
  editQuestion,
  deleteQuestion,
  fetchBookmark,
  addBookmark,
  editBookmark,
  deleteBookmark,
  removeVideo,
  removePPT,
  allCategories,
};

// function fetchAllLessons() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchAllLessons() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
// function allCategories() {
//   return axios
//     .get(
//         `${MoNGO_DB_API_URL}/elearning/category/all/category`
//     , HEADER_TOKEN)
//     .then((response) => response);
// }

function fetchLessonByID(ID) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/${ID}`,
      {
        headers: { Authorization: MY_TOKEN },
      }
    )
    .then((response) => response);
}

function allCategories() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addData(myData) {
//   var sliderArray = [];
//   myData.mySlides.map((item) => {
//     sliderArray.push(item._id);
//   });
//   const data = new FormData();
//   data.append("ecid", myData.ecid);
//   data.append("lesson_name", myData.lesson_name);
//   data.append("lesson_level", myData.lesson_level);
//   data.append("lesson_tags", myData.lesson_tags.join());
//   data.append("slideids", JSON.stringify(sliderArray));
//   data.append("mySlide", JSON.stringify(myData.mySlides));
//   data.append("lesson_description", myData.lesson_description);
//   data.append("featured", myData.featured);
//   data.append("file", myData.lesson_image);
//   var config = {
//     method: "post",
//     url: `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }
function addData(myData) {
  var sliderArray = [];
  myData.mySlides.forEach((element) => {
    sliderArray.push(element._id);
  });
  const lessonTagsArray = Array.isArray(myData.lesson_tags)
    ? myData.lesson_tags.join()
    : myData.lesson_tags.split(",");

  console.log(myData, "MYDATRA");
  let data = new FormData();
  data.append("ecid", myData.ecid);
  data.append("lesson_name", myData.lesson_name);
  data.append("lesson_level", myData.lesson_level);
  data.append("lesson_tags", lessonTagsArray);
  data.append("slideids", JSON.stringify(sliderArray));
  data.append("mySlide", JSON.stringify(myData.mySlides));
  data.append("lesson_description", myData.lesson_description);
  data.append("featured", myData.featured);
  data.append("file", myData.lesson_image);

  // console.log(data, "DATA LESSONSSSs");
  for (let pair of data.entries()) {
    console.log(pair[0] + ": " + pair[1]);
  }
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function editData(myData) {
//   var sliderArray = [];
//   myData.mySlides.map((item) => {
//     sliderArray.push(item._id);
//   });
//   const data = new FormData();
//   data.append("ecid", myData.ecid);
//   data.append("_id", myData._id);
//   data.append("lesson_name", myData.lesson_name);
//   data.append("lesson_level", myData.lesson_level);
//   data.append("desk_id", myData.desk_id);
//   data.append("slideids", JSON.stringify(sliderArray));
//   data.append("lesson_tags", myData.lesson_tags.join());
//   data.append("mySlide", JSON.stringify(myData.mySlides));
//   data.append("lesson_description", myData.lesson_description);
//   data.append("featured", myData.featured);

//   if (myData.changeFile) {
//     data.append("file", myData.lesson_image, myData.lesson_image.name);
//   }

//   var config = {
//     method: "put",
//     url: `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/edit`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }
function editData(myData) {
  var sliderArray = [];
  myData.mySlides.map((item) => {
    sliderArray.push(item._id);
  });
  const lessonTagsArray = Array.isArray(myData.lesson_tags)
    ? myData.lesson_tags.join()
    : myData.lesson_tags.split(",");

  const data = new FormData();
  data.append("ecid", myData.ecid);
  data.append("_id", myData._id);
  data.append("lesson_name", myData.lesson_name);
  data.append("lesson_level", myData.lesson_level);
  data.append("desk_id", myData.desk_id);
  data.append("slideids", JSON.stringify(sliderArray));
  data.append("lesson_tags", lessonTagsArray);
  data.append("mySlide", JSON.stringify(myData.mySlides));
  data.append("lesson_description", myData.lesson_description);
  data.append("featured", myData.featured);

  if (myData.changeFile) {
    data.append("file", myData.lesson_image, myData.lesson_image.name);
  }

  console.log(data, "DATA LESSONSSSs EDIT API");
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function deleteData(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchQuestion(_id) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/question/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchQuestion(_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/question/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addQuestion(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/question`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function addQuestion(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/question`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editQuestion(myData) {
//   const { created_at, ...data } = myData;
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/question/${data._id}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editQuestion(myData) {
  const { created_at, ...data } = myData;
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/question/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteQuestion(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/question/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteQuestion(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/question/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchBookmark(id) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/bookmark/${id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchBookmark(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/bookmark/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addBookmark(data) {
//   var data = {
//     slide_id: data.slide_id,
//     bookmark_name: data.bookmark_name,
//     time: data.time,
//   };
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/bookmark`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function addBookmark(data) {
  var data = {
    slide_id: data.slide_id,
    bookmark_name: data.bookmark_name,
    time: data.time,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/bookmark`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editBookmark(myData) {
//   const { created_at, ...data } = myData;
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/bookmark/${data._id}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editBookmark(myData) {
  const { created_at, ...data } = myData;
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/bookmark/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function removeVideo(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/removevideo/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function removeVideo(_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/removevideo/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function removePPT(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/removeppt/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function removePPT(_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/removeppt/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteBookmark(id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/bookmark/${id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteBookmark(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/bookmark/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
