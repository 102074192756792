import * as React from "react";
import Button from "@mui/material/Button";

export const OutlinedButton = ({
  text,
  icon,
  borderColor,
  textColor,
  disabled,
  onClick,
  size,
  textSize,
}) => {
  const buttonStyle = {
    borderColor: borderColor,
    color: textColor,
    textTransform: "capitalize",
    borderRadius: "7px",
    fontWeight: "500",
    width: size?.width ? size.width : "110px",
    height: size?.height ? size.height : "40px",
    fontSize: textSize || "12px",
  };

  return (
    <Button
    disabled={disabled}
      variant="outlined"
      startIcon={icon ? icon : ""}
      style={buttonStyle}
      onClick={onClick ? onClick : ""}
    >
      {text}
    </Button>
  );
};
