import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./elearningstyles.scss";

import { history } from "../../../../history";
import { useSearch } from "../../context/SearchContext";

import {
  MyCourseIcon,
  FeedbackIcon,
  GradingIcon,
  MyLibraryIcon,
} from "../../../../assets/images/mobileView/icons/svgs";
import { makeStyles } from "@material-ui/core";

const tabs = [
  {
    label: "My Library",
    icon: <MyLibraryIcon fill="black" stroke="yellow" />,
    path: "/elearning/myhelpdesk",
  },
  { label: "My Courses", icon: <MyCourseIcon />, path: "/elearning/mycourses" },
  { label: "Grading", icon: <GradingIcon />, path: "/elearning/grading" },
  { label: "Feedback", icon: <FeedbackIcon />, path: "/elearning/feedbacks" },
];

const useStyle = makeStyles((theme) => ({
  textLabel: {
    color: "#ffffff",
  },
}));

export function ElearningBottomNav() {
  const classes = useStyle();

  const [value, setValue] = useState(0);
  const location = useLocation();

  const { resetSearchQuery } = useSearch();

  useEffect(() => {
    const selectedIndex = tabs.findIndex((tab) =>
      location.pathname.startsWith(tab.path)
    );
    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }

    resetSearchQuery();
  }, [location.pathname]);

  return (
    <div className="elearningbottom-appbar">
      <div className="elearningtabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.label}
            className={`elearningtab ${
              value === index
                ? "elearningtab--elearningfab"
                : value === index - 1
                ? "elearningtab--elearningright"
                : value === index + 1
                ? "elearningtab--elearningleft"
                : ""
            }`}
            onClick={() => history.push(tab.path)}
          >
            {value === index ? (
              <div className="elearningtop">
                <div className="elearningfab">
                  {React.cloneElement(tab.icon, {
                    fill: "#1B3F8F",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
              </div>
            ) : (
              <div className="nonActiveTab">
                <div>
                  {React.cloneElement(tab.icon, {
                    fill: "#ffffff",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
                <span className={classes.textLabel}>{tab.label}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
