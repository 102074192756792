import {
  SET_LESSON_LOADING,
  ADD_LESSON,
  EDIT_LESSON,
  DELETE_LESSON,
  SET_ALL_LESSON,
  SET_FETCH_LESSON_LOADING,
  FETCH_LESSON_BY_ID,
} from "./constants";

const initialState = {
  isLoading: true,
  allLessonData: [],
  isLessonLoading: true,
  LessonData: {},
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LESSON_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_ALL_LESSON:
      return {
        ...state,
        allLessonData: payload.allLessonData,
      };
    case SET_FETCH_LESSON_LOADING:
      return {
        ...state,
        isLessonLoading: payload,
      };
    case FETCH_LESSON_BY_ID:
      return {
        ...state,
        isLessonLoading: false,
        LessonData: payload.result,
      };
    case ADD_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: [...state.allLessonData, payload],
      };
    case EDIT_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: state.allLessonData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    case DELETE_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: state.allLessonData.filter(
          (item) => item._id !== payload
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
