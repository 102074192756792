import {
  MoNGO_DB_API_URL,
  // API_URL,
  HEADER_TOKEN,
  MY_TOKEN,
} from "../../../../config";
import axios from "axios";

export const gradingServices = {
  fetchData,
  fetchAssignLesson,
  // fetchLessonData,
  // fetchManagerStatusData,
  setManagerGrading,
  getLessonData,
  setLessonVideo,
  getVideoURL,
  fetchlocationData,
  fetchRoleData,
  fetchTeamGradingData,
  getLessonNames,
  // addData,
  // editData,
  // deleteData
};

function getLessonNames() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/company`,
      {
        headers: { Authorization: MY_TOKEN },
      }
    )
    .then((response) => response);
}

function fetchTeamGradingData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/team`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

// function fetchData(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/elearning/grading`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/company`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

// function fetchAssignLesson(uid, data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/grading/assigned/lesson/${uid}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function fetchAssignLesson(uid, data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/user/${uid}`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

// function fetchLessonData(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/grading/lessonDetail`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

// function fetchManagerStatusData(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/grading/manager/videoStatusUpdate`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function setManagerGrading(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getLessonData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/lesson_details`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function setLessonVideo(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/video`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getVideoURL(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addData(myData) {
//     const data = new FormData();
//     data.append("category_name", myData.category_name);
//     data.append("file", myData.image, myData.image.name);
//     var config = {
//         method: 'post',
//         url: `${MoNGO_DB_API_URL}/elearning/category`,
//         headers: {
//             'Authorization': MY_TOKEN
//         },
//         data: data
//     };
//     return axios(config).then(response => response)
// };

// function editData(myData) {
//     const data = new FormData();
//     data.append("_id", myData._id);
//     data.append("category_name", myData.category_name);
//     data.append("category_lesson", myData.category_lesson);
//     if (myData.changeFile) {
//         data.append("file", myData.image, myData.image.name);
//     }
//     var config = {
//         method: 'post',
//         url: `${MoNGO_DB_API_URL}/elearning/category/edit`,
//         headers: {
//             'Authorization': MY_TOKEN
//         },
//         data: data
//     };
//     return axios(config).then(response => response)
// };

// function deleteData(_id) {
//     return axios.delete(`${MoNGO_DB_API_URL}/elearning/category/library/${_id}`, HEADER_TOKEN).then(response => response)
// };

function fetchlocationData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/locations`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/roles`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
