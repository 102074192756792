import * as React from "react";
import Box from "@mui/material/Box";
import { RoutineColor, eLearningColor, schedulerColor } from "../../../config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { getMyLessonNames } from "./modules/actions";
import Stack from "@mui/material/Stack";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { history } from "../../../history";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { fetchData } from "./modules/actions";
import {
  FailButton,
  GradientButton,
  PassButton,
  PendingButton,
} from "../Grading/GradingStyles";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactSelect from "react-select";

const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      display: "none",
    },
  },
  root: {
    padding: theme.spacing(5),
  },
  select: {
    borderRadius: "5px",
    "&:before": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
    "&:after": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
    "&:hover": {
      borderColor: "#DEDEDE",
      borderRadius: "5px",
    },
  },
  tabButton: {
    border: `1px solid ${eLearningColor.main}`,
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: 500,
    fontSize: "14px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: RoutineColor.main,
    },
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  typography: {
    color: "#295CDC",
    fontFamily: "Inter",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
  },
  filterButton: {
    color: RoutineColor.main,
    textTransform: "capitalize",
    border: "1px solid white",
    backgroundColor: "white",
    marginLeft: "6px",
    marginBottom: "1px",
    borderRadius: "6px 6px 0px 0px",
    width: "85px",
    "&:hover": {
      color: RoutineColor.main,
      textTransform: "capitalize",
      border: "1px solid white",
      backgroundColor: "white",
      borderRadius: "6px 6px 0px 0px",
      width: "85px",
    },
  },
  image: {
    width: "23px",
    height: "auto",
  },
  toolbarIcons: {
    display: "flex",
    justifyContent: "end",
  },
  icdiv: {
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
  },
  titleShare: {
    color: "rgba(19, 80, 152, 1)",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  publishButton: {
    backgroundColor: RoutineColor.main,
    color: "#ffffff",
    textTransform: "capitalize",
    width: "130px",
    "&:hover": {
      backgroundColor: RoutineColor.main,
    },
  },
  avatar: {
    height: "30px",
    width: "30px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: RoutineColor.main,
    color: "white",
  },
  toolbarTitle: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 600,
  },
  iconImage: {
    border: "1px solid #DEDEDE",
    padding: "5px",
    marginRight: "13px",
    borderRadius: "4px",
    width: "37px",
    height: "34px",
    cursor: "pointer",
  },
  pagination: {
    width: "fit-content",
    marginLeft: "65%",
    padding: "10px",
  },
  searchIconStyle: {
    backgroundColor: "rgba(41, 92, 220, 0.3)",
    borderRadius: "50px",
    padding: "6px",
    marginTop: "-5px",
  },
});

const MyGradingSkeletonLoader = () => {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} sx={{ cursor: "pointer" }}>
      <SkeletonTheme
        baseColor="#ebebeb"
        highlightColor="#f5f5f5"
        duration={4}
      >
        <TableCell
          component="th"
          align="center"
          scope="row"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
          padding="none"
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{
            color: " #848484",
            fontSize: "16px",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ color: " #848484" }}>
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ color: " #848484" }}>
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
      </SkeletonTheme>
    </TableRow>
  );
};

function Progress(props) {
  const {
    classes,
    isProgressLoading,
    headingFlag = true,
  } = props;

  const [data, setData] = React.useState([
    {
      id: "1",
      lesson: "Lesson 1",
      lessonQuiz: "Pass",
      VideoQuiz: "Pass",
      ManagerQuiz: "Pass",
      personalQuiz: "Reassigned",
      status: "1",
      action: "Reassign",
    },
    {
      id: "2",
      lesson: "Lesson 2",
      lessonQuiz: "Pass",
      VideoQuiz: "Pass",
      ManagerQuiz: "Pass",
      personalQuiz: "Pass",
      status: "0",
      action: "View",
    },
    {
      id: "3",
      lesson: "Lesson 3",
      lessonQuiz: "Pass",
      VideoQuiz: "Pass",
      ManagerQuiz: "Pass",
      personalQuiz: "Pass",
      status: "0",
      action: "View",
    },
    {
      id: "4",
      lesson: "Lesson 4",
      lessonQuiz: "Pass",
      VideoQuiz: "Reassigned",
      ManagerQuiz: "Reassigned",
      personalQuiz: "Reassigned",
      status: "1",
      action: "Reassign",
    },
  ]);

  const [page, setpage] = React.useState(1);

  React.useEffect(() => {
    let data = {
      page: page,
      limit: 50,
    };

    props.fetchData(data);
    props.getMyLessonNames();
    setData(props.ProgressData);
  }, []);

  const handleChange = (event, value) => {
    setpage(value);
    let data = {
      page: value,
      limit: 50,
    };
    props.fetchData(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [videoStatus, setVideoStatus] = React.useState("");
  const [lessonStatus, setLessonStatus] = React.useState("");
  const [lessonQuizStatus, setLessonQuizStatus] = React.useState("");
  const [managerStatus, setManagerStatus] = React.useState("");

  const handleChangeDropDownLesson = (event) => {
    let newData = event.value;
    setpage(1);
    let data = {
      page: 1,
      limit: 50,
    };

    if (newData) {
      data.lesson_id = newData;
    }

    if (videoStatus) {
      data.video_quiz_status = videoStatus;
    }
    if (lessonQuizStatus) {
      data.lesson_quiz_status = lessonQuizStatus;
    }
    if (managerStatus) {
      data.manager_quiz_status = managerStatus;
    }

    props.fetchData(data);
    setLessonStatus(newData);
  };

  const handleChangeDropDownLessonQuizStatus = (event) => {
    let newData = event.target.value;
    setpage(1);
    let data = {
      page: 1,
      limit: 50,
    };

    if (newData) {
      data.lesson_quiz_status = newData;
    }

    if (lessonStatus) {
      data.lesson_status = lessonStatus;
    }
    if (videoStatus) {
      data.video_quiz_status = videoStatus;
    }
    if (managerStatus) {
      data.manager_quiz_status = managerStatus;
    }

    props.fetchData(data);
    setLessonQuizStatus(newData);
  };

  const handleChangeDropDownVideo1 = (event) => {
    setpage(1);
    let newData = event.target.value;
    let data = {
      page: 1,
      limit: 50,
    };

    if (newData) {
      data.video_quiz_status = newData;
    }

    if (lessonStatus) {
      data.lesson_status = lessonStatus;
    }
    if (lessonQuizStatus) {
      data.lesson_quiz_status = lessonQuizStatus;
    }
    if (managerStatus) {
      data.manager_quiz_status = managerStatus;
    }

    props.fetchData(data);
    setVideoStatus(newData);
  };

  const handleChangeDropDownManagerStatus = (event) => {
    let newData = event.target.value;

    setpage(1);
    let data = {
      page: 1,
      limit: 50,
    };

    if (newData) {
      data.manager_quiz_status = newData;
    }

    if (lessonStatus) {
      data.lesson_status = lessonStatus;
    }
    if (lessonQuizStatus) {
      data.lesson_quiz_status = lessonQuizStatus;
    }
    if (videoStatus) {
      data.video_quiz_status = videoStatus;
    }

    props.fetchData(data);
    setManagerStatus(newData);
  };

  const handleSubmitVideo = (e, _id, ecid, lesson_result_id) => {
    history.push(`/elearning/grading/video/${_id}/${lesson_result_id}`);
  };

  const handleView = (e, ecid, _id) => {
    window.open(`/elearning/myhelpdesk/slider/${ecid}/${_id}`, "_blank");
  };

  const numberOfSkeletons = 20;

  const MyGradingSkeletonArray = Array.from(
    { length: numberOfSkeletons },
    (_, index) => (
      <MyGradingSkeletonLoader
        key={index}
      />
    )
  );

  return (
    <Box sx={{ display: "flex", background: "#F7F8FC" }}>
      <Grid container spacing={4} className={classes.root}>
        {headingFlag && (
          <Grid item md={12} className={classes.flex}>
            <Grid item md={12}>
              <Typography className={classes.titleShare}>
                My Progress
              </Typography>
            </Grid>
          </Grid>
        )}
        <Stack>
          <Box
            mx={"1.5rem"}
            mb={"2.5rem"}
            display={"flex"}
            gap={"20px"}
            flexWrap={"wrap"}
            style={{
              justifyContent: window.innerWidth < 700 ? "center" : "start",
            }}
          >
            <FormControl
              sx={{
                width: 170,
                borderRadius: "10px",
                border: "1px solid transparent",
                // bgcolor: "#ffffff",
                "& .MuiSvgIcon-root": { color: "#1B3F8F" },
              }}
              size="small"
            >
              <ReactSelect
                options={[
                  { value: "", label: "All Lessons" },
                  ...props.myLessonNamesData.map((item) => ({
                    value: item._id,
                    label: item?.lesson_name,
                  })),
                ]}
                value={lessonStatus}
                placeholder={
                  props.myLessonNamesData.find(
                    (item) => item._id === lessonStatus
                  )
                    ? props.myLessonNamesData.find(
                      (item) => item._id === lessonStatus
                    ).lesson_name
                    : "Lesson"
                }
                onChange={handleChangeDropDownLesson}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    borderRadius: "10px!important",
                    background: "#ffffff",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#1B3F8F",
                  }),
                }}
              />
            </FormControl>
            <FormControl
              sx={{
                width: 170,
                borderRadius: "10px",
                border: "1px solid transparent",
                "& .MuiSvgIcon-root": { color: "#1B3F8F" },
              }}
              size="small"
            >
              <Select
                IconComponent={ExpandMoreIcon}
                dropDownMenuProps={{
                  iconButton: <ExpandMoreIcon />,
                }}
                sx={{
                  borderRadius: "10px!important",
                  bgcolor: "#ffffff",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                onChange={(event) =>
                  handleChangeDropDownLessonQuizStatus(event)
                }
                value={lessonQuizStatus}
                displayEmpty
                placeholder="Payroll Provider intergration"
              >
                <MenuItem style={{ fontSize: "8px" }} value={""}>
                  Lesson Quiz
                </MenuItem>
                <MenuItem value={"Passed"}>Passed</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
              </Select>
            </FormControl>{" "}
            <FormControl
              sx={{
                width: 170,
                borderRadius: "10px",
                border: "1px solid transparent",
                // bgcolor: "#ffffff",
                "& .MuiSvgIcon-root": { color: "#1B3F8F" },
              }}
              size="small"
            >
              <Select
                IconComponent={ExpandMoreIcon}
                dropDownMenuProps={{
                  iconButton: <ExpandMoreIcon />,
                }}
                sx={{
                  borderRadius: "10px!important",
                  bgcolor: "#ffffff",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                onChange={(event) => handleChangeDropDownManagerStatus(event)}
                value={managerStatus}
                displayEmpty
                placeholder="Payroll Provider intergration"
              >
                <MenuItem style={{ fontSize: "8px" }} value={""}>
                  Manager Quiz
                </MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Pass">Pass</MenuItem>
                <MenuItem value="Reassigned">Reassigned</MenuItem>
              </Select>
            </FormControl>{" "}
            <FormControl
              sx={{
                width: 170,
                borderRadius: "10px",
                border: "1px solid transparent",
                "& .MuiSvgIcon-root": { color: "#1B3F8F" },
              }}
              size="small"
            >
              <Select
                IconComponent={ExpandMoreIcon}
                dropDownMenuProps={{
                  iconButton: <ExpandMoreIcon />,
                }}
                sx={{
                  borderRadius: "10px!important",
                  bgcolor: "#ffffff",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                onChange={(event) => handleChangeDropDownVideo1(event)}
                value={videoStatus}
                displayEmpty
                placeholder="Payroll Provider intergration"
              >
                <MenuItem value="">Video Quiz</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Pass">Pass</MenuItem>
                <MenuItem value="Reassigned">Reassigned</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Grid item style={{ marginTop: "-16px" }} xs={12}>
          <Paper
            style={{
              borderRadius: "10px",
              overflowX: "auto",
            }}
          >
            <TableContainer
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <Table
                className={classes.table}
                size="medium"
              >
                <TableHead
                  style={{
                    background: "rgba(27, 63, 143, 0.25)",
                    height: "64px",
                  }}
                >
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Lesson Name
                    </TableCell>{" "}
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Lesson quiz
                    </TableCell>{" "}
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Video Quiz
                    </TableCell>{" "}
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Manager Quiz
                    </TableCell>{" "}
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Status
                    </TableCell>{" "}
                    <TableCell
                      style={{
                        color: "#1B3F8F",
                        backgroundColor: "#1B3F8F40",
                        fontWeight: "400",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isProgressLoading
                    ? MyGradingSkeletonArray
                    : props.ProgressData.length > 0 &&
                    props.ProgressData.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                        >
                          <TableCell
                            component="th"
                            align="center"
                            scope="row"
                            style={{
                              color: " #848484",
                              fontSize: "16px"
                            }}
                            padding="none"
                          >
                            {row.lesson_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: " #848484",
                              fontSize: "16px",
                            }}
                          >
                            {row.lesson_quiz_status}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {row.video_quiz_status === "Pending" ||
                              row.video_quiz_status === "Reassigned" ? (
                              row.lesson_result &&
                                row.lesson_result.video_url ? (
                                <Box>
                                  <Typography
                                    style={{
                                      color: "#848484",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row.video_quiz_status}
                                  </Typography>
                                </Box>
                              ) : row.lesson_result ? (
                                <GradientButton
                                  variant="contained"
                                  value="Record"
                                  onClick={(e) => {
                                    handleSubmitVideo(
                                      e,
                                      row._id,
                                      row.ecid,
                                      row.lesson_result._id
                                    );
                                  }}
                                >
                                  Record
                                </GradientButton>
                              ) : (
                                <Box>
                                  <Typography
                                    style={{
                                      color: "#848484",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row.video_quiz_status}
                                  </Typography>
                                </Box>
                              )
                            ) : (
                              <Box>
                                <Typography
                                  style={{
                                    color: "#848484",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                >
                                  {row.video_quiz_status}
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: " #848484",
                              fontSize: "16px"
                            }}
                          >
                            {row.manager_quiz_status}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: " #848484" }}
                          >
                            {row.overall_status === "Pending" ? (
                              <PendingButton
                                variant="contained"
                                disableElevation
                              >
                                Pending
                              </PendingButton>
                            ) : row.overall_status === "Pass" ? (
                              <PassButton
                                variant="contained"
                                disableElevation
                              >
                                Pass
                              </PassButton>
                            ) : (
                              <FailButton
                                variant="contained"
                                disableElevation
                              >
                                Fail
                              </FailButton>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: " #848484" }}
                          >
                            <GradientButton
                              variant="contained"
                              onClick={(e) =>
                                handleView(e, row.ecid, row._id)
                              }
                            >
                              View
                            </GradientButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

              {!isProgressLoading && props.ProgressData.length === 0 && (
                <Box
                  textAlign="center"
                >
                  <Typography
                    style={{
                      color: "#848484",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    spacing={2}
                    m={"1rem"}
                  >
                    No Record Found
                  </Typography>
                </Box>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Stack spacing={2} m={"1rem"}>
                  <Pagination
                    color="primary"
                    count={props.totalPage}
                    variant="outlined"
                    page={props.pageNumber}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ProgressData: state.elearningProgress.ProgressData,
  isProgressLoading: state.elearningProgress.isProgressLoading,
  totalPages: state.elearningProgress.totalPage,
  pageNumber: state.elearningProgress.pageNumber,
  myLessonNamesData: state.elearningProgress.myLessonNamesData,
  isMyLessonNamesDataLoading:
    state.elearningProgress.isMyLessonNamesDataLoading,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  getMyLessonNames,
})(Progress);

export default withStyles(styles)(ConnectWith);
