import { useEffect, useState } from "react";

import { getMyLessons } from "../../../../pages/eLearning/MyCourses/modules/actions";

// icons
import { mobileTheme } from "../../constant";
import { CourseCard } from "../../components/Cards";
import { Toggel } from "../../utilities/common/Button";

import "../style.css";
import { connect } from "react-redux";
import axios from "axios";
import { HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import { Pagination, Stack } from "@mui/material";
import { MobileLoader } from "../../utilities";
import { CategoriesBox } from "../../components";
import { Box, Typography } from "@material-ui/core";

import "./MobileCourseListing.scss";

const MyCourseMobile = ({ categoryData, isLoading }) => {
  const [selectedTab, setSelectedTab] = useState("pending");
  const [lessonCategories, setLessonCategories] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [lessonsLoading, setLessonsLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [totalLessonsProgress, setTotalLessonProgress] = useState(0);

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const fetchData = () => {
    setCategoryLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse`,
        HEADER_TOKEN
      )
      .then((response) => {
        if (response.data.success) {
          setLessonCategories(response.data.myCourseData);
          setCategoryLoading(false);
        }
      });
  };

  const fetchLessons = (data) => {
    setLessonsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/app/elearning/mycourse/lessons`,
        {
          params: data,
          headers: {
            Authorization: MY_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          console.log(response.data, "Lessosssnsss");
          setPageCount(response.data.totalPages);
          setPageNumber(response.data.currentPage);
          setLessonsLoading(false);
          setLessons(response.data.result);
        }
      });
  };

  useEffect(() => {
    fetchData();

    let data = {
      page: 1,
      limit: 10,
    };
    if (selectedTab === "pending") {
      data.is_completed = 0;
    } else {
      data.is_completed = 1;
    }

    if (selectedCategory !== "all") {
      data.ecid = selectedCategory;
    }

    fetchLessons(data);
  }, [selectedTab]);

  useEffect(() => {
    console.log(lessonCategories, "Lesson Categories");

    if (lessonCategories) {
      let totalProgressSum = 0;
      for (let i = 0; i < lessonCategories.length; i++) {
        totalProgressSum += lessonCategories[i].cat_per;
      }
      setTotalLessonProgress(totalProgressSum / lessonCategories.length);
    }

    console.log("234", totalLessonsProgress, "123");
  }, [lessonCategories]);

  const handleCategoryChange = (ecid) => {
    setSelectedCategory(ecid);
    let data = {
      page: pageNumber,
      limit: 10,
    };
    if (selectedTab === "pending") {
      data.is_completed = 0;
    } else {
      data.is_completed = 1;
    }
    if (ecid !== "all") {
      data.ecid = ecid;
    }
    fetchLessons(data);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);

    let data = {
      page: value,
      limit: 10,
    };
    if (selectedTab === "pending") {
      data.is_completed = 0;
    } else {
      data.is_completed = 1;
    }

    if (selectedCategory !== "all") {
      data.ecid = selectedCategory;
    }

    fetchLessons(data);
  };

  const getCategoryName = (lesson) => {
    const currentTab = lessonCategories?.find(
      (tab) => tab.ecid._id === lesson.ecid
    );
    return currentTab ? currentTab.ecid.category_name : "Unknown Category";
  };

  return (
    <Box className={"courseMobileRoot"} disableScrollLock={true}>
      <Box className={"courseMobileMainContainer"}>
        <CategoriesBox
          categoryLoading={categoryLoading}
          handleCategoryChange={handleCategoryChange}
          lessonCategories={lessonCategories}
          selectedCategory={selectedCategory}
          totalLessonsProgress={totalLessonsProgress}
        />

        <Box className={"courseMobileToggleContainer"}>
          <Toggel
            options={[
              { id: 1, heading: "pending", value: "pending" },
              { id: 2, heading: "completed", value: "completed" },
            ]}
            tabSize={{ width: "93px", height: "35px" }}
            selected={selectedTab}
            selectedBgColor={mobileTheme}
            size={{ width: "178px", height: "35px" }}
            handleTabChange={handleTabChange}
          />
        </Box>
        {lessonsLoading ? (
          <Box className={"courseMobileLoadingContainer"}>
            <MobileLoader />
          </Box>
        ) : (
          <Box className={"courseMobileLessonContainer"}>
            {lessons.length > 0 ? (
              lessons?.map((item, index) => (
                <CourseCard
                  key={item._id}
                  slides={item.lesson_slides}
                  name={item?.lesson_name}
                  description={item.lesson_description}
                  image={item?.lesson_image}
                  progress={(item.completed_slides / item.totleslides) * 100}
                  categoryName={getCategoryName(item)}
                  ecid={item?.ecid}
                  id={item?._id}
                />
              ))
            ) : (
              <Typography className={"courseMobileTextWrapper"}>
                No Lessons
              </Typography>
            )}
          </Box>
        )}
        <Box className={"courseMobilePaginationContainer"}>
          <Stack spacing={2} m={"1rem"}>
            <Pagination
              size="small"
              color="primary"
              count={pageCount}
              variant="outlined"
              page={pageNumber}
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  // isLoading: state.eLearningMyCourses.isLoading,
});

const ConnectWith = connect(mapStateToProps, {
  getMyLessons,
})(MyCourseMobile);

export default ConnectWith;
