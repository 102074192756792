import React, { Component, } from "react";
import { withStyles } from "@material-ui/core/styles";
import { fetchData, fetchDataNew, fetchPayrollTimecode } from "./modules/actions";
import { fetchData as fetchTeamUsers } from "../Team/modules/actions";
import { NoPermission } from "../../../components";
import { connect } from "react-redux";
import ColleapseTable from "./ColleapseTable";
import { LoadingData, MyPlain25Modal, DeleteModal } from "../../../components";
import { fetchData as fetchRolesData } from "../Roles/modules/actions";
import { alert, localTimeToUtc, utcDateToLocal, utcToLocal } from "../../../utilities";
import { Build as ToolsIcon } from "@material-ui/icons";
import FullModal from "./FullModal";
import { Grid, Button } from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TimesheetToolbar from "./TimesheetToolbar";
import ShowHideMenus from "./ShowHideMenus";
import PrintModal from "./PrintModal";
import DownloadModal from "./DownloadModal";
import AddModal from "./AddModal";
import ViewModal from "./ViewModal";
import PayrollTimesheet from "./PayrollTimesheet";
import AddTimecard from "./AddTimecard";
import ViewTimecard from "./ViewTimecard";
import PayrollPopover from "./PayrollPopover";
import FilterPopover from "./FilterPopover";
import { timesheetServices } from "./modules/services";
import { schedulerColor } from "../../../config";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    myButton: {
        backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
        boxShadow: 'none',
        fontWeight: 600,
        color: '#ffffff',
        borderRadius: "1px",
        border: `1px solid linear-gradient(45deg, #AA076B, #9F0D9C)`,
        padding: '5px 20px',
        '&:hover': {
            backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
        }

    },
    myFilterButton: {
        backgroundColor: "white",
        boxShadow: 'none',
        fontWeight: 600,
        fontSize: 13,
        color: schedulerColor.main,
        marginLeft: 4,
        textAlign: "center",
        borderRadius: "1px",
        border: `1px solid ${schedulerColor.main}`,
        padding: '2px',
        '&:hover': {
            backgroundColor: schedulerColor.main,
        }

    }
});

export function fetchTimesheetsData() {
    window.Timesheets.fetchData(true);
}

export const MContext = React.createContext();

class Timesheets extends Component {
    constructor(props) {
        super(props)
        window.Timesheets = this;
        this.state = {
            myArray: [],
            myIndex: 0,
            isTimecardEdit: 0,
            showHideSettings: {
                name: 1,
                timecard: 1,
                payroll_id: 0,
                payrollrule:1,
                wage_rate: 0,
                regular: 0,
                scheduled: 1,
                actual: 1,
                sch_vs_paid_diff: 1,
                sch_vs_actual_diff: 1,
                total_paid: 1,
                unpaid_breaks: 1,
                estimated_wages: 1,
                issues: 1,
            },
            showHideData: [
                {
                    title: "Name",
                    sortName: "name"
                },
                {
                    title: "Timecards",
                    sortName: "timecard"
                },
                // {
                //     title: "Payroll ID",
                //     sortName: "payroll_id"
                // },
                // {
                //     title: "Wage Rate",
                //     sortName: "wage_rate"
                // },
                // {
                //     title: "Regular",
                //     sortName: "regular"
                // },
                {
                    title: "Scheduled",
                    sortName: "scheduled"
                },
                {
                    title: "Actual",
                    sortName: "actual"
                },
                // {
                //     title: "Scheduled vs Paid Diff",
                //     sortName: "sch_vs_paid_diff"
                // },
                // {
                //     title: "Scheduled vs Actual Diff",
                //     sortName: "sch_vs_actual_diff"
                // },
                {
                    title: "Total Paid",
                    sortName: "total_paid"
                },
                {
                    title: "Unpaid Breaks",
                    sortName: "unpaid_breaks"
                },
                {
                    title: "Estimated Wages",
                    sortName: "estimated_wages"
                },
                {
                    title: "Issues",
                    sortName: "issues"
                }
            ],
            data: [],
            toolsData: [
                {
                    title: "Hide Employees with No Hours",
                    sortName: "Hide_Employees_With_No_Hours",
                    isCheckbox: 1
                },
                {
                    title: "Display Edited Timecard Only",
                    sortName: "Display_Edited_Timecard_Only",
                    isCheckbox: 1
                },
                {
                    title: "Hide employee with no timecard",
                    sortName: "Hide_Employees_With_No_Timecard",
                    isCheckbox: 1
                },
                {
                    title: "Display unscheduled shifts only",
                    sortName: "Display_Unscheduled_Shifts_Only",
                    isCheckbox: 1
                },
                {
                    title: "Display Scheduled shifts only",
                    sortName: "Display_Scheduled_Shifts_Only",
                    isCheckbox: 1
                },
            ],
            toolbarData: [
                {
                    icon: ToolsIcon,
                    title: "Current",
                    sortName: "current",
                    onClick: this.handlePopoverOpen
                },
                {
                    icon: ToolsIcon,
                    title: "View Detail",
                    sortName: "edit",
                    onClick: this.handleToggleDetails
                },
                {
                    icon: ToolsIcon,
                    title: "Add Timecard",
                    sortName: "edit",
                    onClick: this.openTimecardModal
                },
                {
                    icon: ToolsIcon,
                    title: "Download",
                    sortName: "download",
                    onClick: this.openDownloadModal
                }
            ],
            deleteTimecardModal:false,
            printList: [
                {
                    type: 0,
                    title: "All timecards",
                    sub_title: "Prints all timecards together",
                },
                // {
                //     type: 1,
                //     title: "Timecards by Employee",
                //     sub_title: "Prints each employee on a separate page",
                // }
            ],
            payrollFilterData: [
                {
                    id: "current",
                    title: "Current Payroll Period"
                },
                {
                    id: "previous",
                    title: "Previous Payroll Period"
                },
                {
                    id: "today",
                    title: "Today"
                },
                {
                    id: "custom",
                    title: "Custom"
                }
            ],
            display_timecard: 1,
            displayPayPeriod: "current",
            payPeriod: "current",
            timecardStep: 0,
            myItem: {},
            errors: {},
            errorsHelper: {},
            printModal: false,
            toolbarSettings: {},
            timecodeData: [],
            is_expanded: true,
            usersData: [],
            todayDate: moment().format("YYYY-MM-DD"),
            current_startDate: null,
            current_endDate: null,
            previous_startDate: null,
            previous_endDate: null,
            custom_startDate: null,
            custom_endDate: null,
            popoverFilterAnchor: null,
            filteremployee_uid: "",
            filtersort_by: "",
            nextDay: false,
            filtergroup_by: "",
            status_is_approved:"4",
            hideshowfilters: {
                "Display_Edited_Timecard_Only": "",
                "Display_Unscheduled_Shifts_Only": "",
                "Hide_Employees_With_No_Hours": "",
                "Hide_Employees_With_No_Timecard": "",
                "Display_Scheduled_Shifts_Only": ""
            },
            timecardModal: false,
            downloadModal: false,
            timecardLoading: false,
            deleteBreakModal: false,
            indexBreak: ""
        }
    }

    fetchData = (isReload) => {
        var data = {
            lid: this.props.scheduler_lid,
            //payPeriod:this.state.payPeriod
        }
        this.props.fetchData(data)
    }

    fetchDataNew = () => {
        var data = {
            lid: this.props.scheduler_lid,
            //payPeriod:this.state.payPeriod
        }
        this.props.fetchDataNew(data)
        this.props.fetchPayrollTimecode()
    }

    // componentDidUpdate = () => {
    //     moment.tz.setDefault(this.props.timezone[0].location_timezone);
    //   }


    componentDidMount = () => {
        moment.tz.setDefault("");
        var lid = this.props.scheduler_lid
        this.props.fetchRolesData(false,1)
        this.props.fetchTeamUsers(lid)

        this.setState({
            usersData: this.props.usersData,
            usersDataTemp: this.props.usersData,
            timecodeData: this.props.timecodeData,
            toolbarSettings: this.props.toolbarSettings,
            current_startDate: this.props.current_startDate,
            current_endDate: this.props.current_endDate,
            previous_startDate: this.props.previous_startDate,
            previous_endDate: this.props.previous_endDate,
            hideshowfilters: {
                "Display_Edited_Timecard_Only": this.props.Display_Edited_Timecard_Only,
                "Display_Unscheduled_Shifts_Only": this.props.Display_Unscheduled_Shifts_Only,
                "Hide_Employees_With_No_Hours": this.props.Hide_Employees_With_No_Hours,
                "Hide_Employees_With_No_Timecard": this.props.Hide_Employees_With_No_Timecard,
                "Display_Scheduled_Shifts_Only": this.props.Display_Scheduled_Shifts_Only
            },
            filteremployee_uid: this.props.filter_employee_uid,
            filtergroup_by: this.props.filter_group_by,
            status_is_approved:this.props.status_is_approved,
            filtersort_by: this.props.filter_sort_by,
            custom_startDate: this.props.custom_startDate,
            custom_endDate: this.props.custom_endDate
        })
            if(this.props.match.params.id && this.props.match.params.date){
            var filters = {
                lid: this.props.scheduler_lid,
                filter_sort_by: "name",
                filter_group_by: "employee",
                filter_employee_uid: this.props.match.params.id ?  this.props.match.params.id : "0",
                status_is_approved:this.props.status_is_approved,
                payPeriod: this.state.payPeriod,
                startDate: this.props.match.params.date ? this.props.match.params.date : this.state.current_startDate,
                endDate: this.props.match.params.date ?  this.props.match.params.date : this.state.custom_endDate,
                Hide_Employees_With_No_Hours: this.props.Hide_Employees_With_No_Hours,
                Hide_Employees_With_No_Timecard: this.props.Hide_Employees_With_No_Timecard,
                Display_Edited_Timecard_Only: this.props.Display_Edited_Timecard_Only,
                Display_Unscheduled_Shifts_Only: this.props.Display_Unscheduled_Shifts_Only,
                Display_Scheduled_Shifts_Only: this.props.Display_Scheduled_Shifts_Only
            }
            this.setState(
                {
                    popoverFilterAnchor: null,
                    filteremployee_uid:this.props.match.params.id,
                },
                () => {
                    this.props.fetchDataNew(filters);
                }
            );
        }else{
            this.fetchData(false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        moment.tz.setDefault("");
        if (this.props.current_startDate && this.props.current_endDate && !this.state.custom_startDate && !this.state.custom_endDate) {
            this.setState({
                custom_startDate: this.props.current_startDate,
                custom_endDate: this.props.current_endDate
            })
        }

        if (this.props.current_startDate && this.props.current_endDate && !this.state.current_endDate && !this.state.current_endDate) {
            this.setState({
                usersData: this.props.usersData,
                usersDataTemp: this.props.usersData,
                timecodeData: this.props.timecodeData,
                toolbarSettings: this.props.toolbarSettings,
                current_startDate: this.props.current_startDate,
                current_endDate: this.props.current_endDate,
                previous_startDate: this.props.previous_startDate,
                previous_endDate: this.props.previous_endDate,
                hideshowfilters: {
                    "Display_Edited_Timecard_Only": this.props.Display_Edited_Timecard_Only,
                    "Display_Unscheduled_Shifts_Only": this.props.Display_Unscheduled_Shifts_Only,
                    "Hide_Employees_With_No_Hours": this.props.Hide_Employees_With_No_Hours,
                    "Hide_Employees_With_No_Timecard": this.props.Hide_Employees_With_No_Timecard,
                    "Display_Scheduled_Shifts_Only": this.props.Display_Scheduled_Shifts_Only
                },
                filteremployee_uid: this.props.filter_employee_uid,
                filtergroup_by: this.props.filter_group_by,
                status_is_approved:this.props.status_is_approved,
                filtersort_by: this.props.filter_sort_by,
                payPeriod: this.props.payPeriod,
                custom_startDate: this.props.custom_startDate,
                custom_endDate: this.props.custom_endDate
            })
        }


        if ((JSON.stringify(prevProps.usersData) !== JSON.stringify(this.props.usersData))) {
            this.setState({
                usersData: this.props.usersData,
                usersDataTemp: this.props.usersData,
                timecodeData: this.props.timecodeData,
                toolbarSettings: this.props.toolbarSettings,
                current_startDate: this.props.current_startDate,
                current_endDate: this.props.current_endDate,
                previous_startDate: this.props.previous_startDate,
                previous_endDate: this.props.previous_endDate,
                hideshowfilters: {
                    "Display_Edited_Timecard_Only": this.props.Display_Edited_Timecard_Only,
                    "Display_Unscheduled_Shifts_Only": this.props.Display_Unscheduled_Shifts_Only,
                    "Hide_Employees_With_No_Hours": this.props.Hide_Employees_With_No_Hours,
                    "Hide_Employees_With_No_Timecard": this.props.Hide_Employees_With_No_Timecard,
                    "Display_Scheduled_Shifts_Only": this.props.Display_Scheduled_Shifts_Only
                },
                filteremployee_uid: this.props.filter_employee_uid,
                filtergroup_by: this.props.filter_group_by,
                filtersort_by: this.props.filter_sort_by,
                status_is_approved:this.props.status_is_approved,
                payPeriod: this.props.payPeriod,
                custom_startDate: this.props.custom_startDate,
                custom_endDate: this.props.custom_endDate
            })
        }
    }



    handleClickViewDetailsSingle = (index) => {
        var tempData = this.state.usersData;
        var value = tempData[index].is_expanded ? false : true;
        tempData[index].is_expanded = value;
        this.setState({
            usersData: tempData,
        });
    };

    handleToggleDetails = (value) => {
        this.setState({ is_expanded: value ? true : false }, () => {
            var tempData = this.state.usersData;
            tempData.map((item) => {
                const cloneItem = item;
                cloneItem["is_expanded"] = this.state.is_expanded ? false : true;
                return cloneItem;
            });
            this.setState({ usersData: tempData });
        });
    };

    handleShowHideOpen = (event) => {
        this.setState({ toolsAnchor: event.currentTarget });
    };

    handleShowHideClose = (value) => {
        this.setState({ toolsAnchor: null });
    };

    // updateToolsSettings = (state) => {
    //     let value = this.state.toolbarSettings[state] ? 0 : 1;
    //     this.setState(
    //         (prevState) => ({
    //             toolbarSettings: {
    //                 ...prevState.toolbarSettings,
    //                 [state]: value,
    //             },
    //         }),
    //         () => {
    //             if (state === "hide_employee_no_hours") {
    //                 this.hideEmployeeWithNoHours();
    //             }
    //             if (state === "edited_timecard_only") {
    //                 this.editedTimecardOnly();
    //             }
    //             if (state === "unscheduled_shifts_only") {
    //                 this.unscheduledShiftsOnly();
    //             }
    //             if (state === "add_hide_employees_no_timecard") {
    //                 this.addHideEmployeesNoTimecard();
    //             }
    //             var data = {
    //                 toolbarSettings: this.state.toolbarSettings,
    //             };
    //             scheduleServices.updateToolsSettings(data).then(response => {
    //             });
    //         }
    //     );
    // };

    // hideEmployeeWithNoHours = () => {
    //     var isChecked = this.state.toolbarSettings.hide_employee_no_hours;
    //     if (isChecked) {
    //         var tempData = this.state.usersData.filter(function (user) {
    //             user.is_expanded = false;
    //             if (parseFloat(user.total_timecard) > 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     } else {
    //         var tempData = this.state.usersDataTemp;
    //         tempData.map((item) => {
    //             const cloneItem = item;
    //             cloneItem["is_expanded"] = false;
    //             return cloneItem;
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     }
    // };


    // unscheduledShiftsOnly = () => {
    //     var isChecked = this.state.toolbarSettings.unscheduled_shifts_only;
    //     if (isChecked) {
    //         var usersData = this.props.usersData.filter(function (user) {
    //             var myShifts = user.myShifts.filter((shifts) => shifts.isScheduled === 0);
    //             user.is_expanded = false;
    //             user.myShifts = myShifts;
    //             if (myShifts.length > 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         });
    //         this.setState({
    //             usersData,
    //         });
    //     } else {
    //         var tempData = this.state.usersDataTemp;
    //         tempData.map((item) => {
    //             const cloneItem = item;
    //             cloneItem["is_expanded"] = false;
    //             return cloneItem;
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     }
    // };

    // addHideEmployeesNoTimecard = () => {

    //     var isChecked = this.state.toolbarSettings.add_hide_employees_no_timecard;
    //     if (isChecked) {
    //         var tempData = this.state.usersData.filter(function (user) {
    //             // user.is_expanded = false;
    //             if (parseFloat(user.total_timecard) > 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     } else {
    //         var tempData = this.state.usersDataTemp;
    //         tempData.map((item) => {
    //             const cloneItem = item;
    //             // cloneItem["is_expanded"] = false;
    //             return cloneItem;
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     }
    // };


    // editedTimecardOnly = () => {
    //     var isChecked = this.state.toolbarSettings.edited_timecard_only;
    //     if (isChecked) {
    //         var usersData = this.props.usersData.filter(function (user) {
    //             var myShifts = user.myShifts.filter((shifts) => shifts.is_edited === 1);
    //             user.is_expanded = false;
    //             user.myShifts = myShifts;
    //             if (myShifts.length > 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         });
    //         this.setState({
    //             usersData
    //         });
    //     } else {
    //         var tempData = this.state.usersDataTemp;
    //         tempData.map((item) => {
    //             const cloneItem = item;
    //             cloneItem["is_expanded"] = false;
    //             return cloneItem;
    //         });
    //         this.setState({
    //             usersData: tempData,
    //         });
    //     }
    // };

    printModalOpen = () => {
        this.setState({
            printModal: true,
        });
    };

    printModalClose = () => {
        this.setState({
            printModal: false,
        });
    };

    printPayrollSummary = () => {
        const tableColumn = [
            this.state.showHideSettings.name ?  "Name" : "",
            "Payroll Rule",
            this.state.showHideSettings.timecard ? "Total Timecard" : "",
            this.state.showHideSettings.scheduled ? "Total Scheduled" : "",
            this.state.showHideSettings.actual ? "Total Actual" : "",
            // "Total Sch vs Paid",
            // "Total Sch vs Actual",
            this.state.showHideSettings.total_paid ? "Total Paid" : "",
            this.state.showHideSettings.unpaid_breaks ? "Total Breaks" : "",
            this.state.showHideSettings.issues ? "Total Issues" : "",
        ];

        const tableRows = [];
        this.state.usersData.map((rows, index) => {
            var weekArray = [];
            weekArray.push(this.state.showHideSettings.name  ?rows.user_name.trim() : "");
              weekArray.push(rows.payrollrule);
            weekArray.push(this.state.showHideSettings.timecard ? rows.total_timecard :"");
             weekArray.push(this.state.showHideSettings.scheduled ? rows.total_scheduled_hrs:"");
             weekArray.push(this.state.showHideSettings.actual ? rows.total_actual_hrs:"");
            // weekArray.push(rows.total_sch_vs_paid_hrs);
            // weekArray.push(rows.total_sch_vs_actual_hrs);
           weekArray.push(   this.state.showHideSettings.total_paid  ? rows.total_paid_hrs:"");
             weekArray.push(this.state.showHideSettings.unpaid_breaks ? rows.total_break_hrs:"");
            weekArray.push(this.state.showHideSettings.issues ? rows.total_issues:"");
            tableRows.push(weekArray);
        });

        const doc = new jsPDF("l", "mm", "a4");
        // doc.text("Skylinx East Tue Feb 9 - Tue Feb 16, 2021", 14, 15);
        doc.autoTable({
            theme: "plain",
            // pageBreak: "auto",
            headStyles: {
                minCellHeight: 20,
                fontSize: 12,
                cellPadding: 3,
                fontStyle: "bold",
            },
            styles: {
                minCellHeight: 15,
                fontSize: 9,
                halign: "left",
                valign: "middle",
                fillColor: "#fefefe",
                textColor: "black",
                fontStyle: "normal",
            },
            margin: { top: 10 },
            head: [tableColumn],
            body: tableRows,
        });

        this.setState({ printModal: false });
        const date = Date().split(" ");
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        doc.save(`timesheets_${dateStr}.pdf`);
    };

    printData = () => {
        const tableColumn = [
            "Date",
            "Role",
            this.state.showHideSettings.timecard ? "Timecard" : "",
            this.state.showHideSettings.scheduled ? "Scheduled" : "",
            this.state.showHideSettings.actual ? "Actual" : "",
            // "Sch vs Paid",
            // "Sch vs Actual",
            this.state.showHideSettings.total_paid ? "Total paid" : "",
            this.state.showHideSettings.unpaid_breaks ? "Total break" : "",
            this.state.showHideSettings.issues ? "Issue" : "",
        ];

        const tableRows = [];
        this.state.usersData.map((rows, index) => {
            if (rows.myShifts.length > 0) {
                var weekArray = [];
                weekArray.push(rows.user_name.trim());
                tableRows.push(weekArray);
                rows.myShifts.map((shift) => {
                    var startTiime = new Date(
                        shift.isScheduled === 1 
                          ? shift.startTime
                          : shift.revised_clock_in
                      );
                      var endTiime = new Date(
                        shift.isScheduled === 1
                          ? shift.endTime
                          : shift.revised_clock_out
                      );
                      var clock_in = startTiime.toLocaleString("en-US", {
                        timeZone: this.props.timezone[0].location_timezone,
                      });
                      var clock_out = endTiime.toLocaleString("en-US", {
                        timeZone: this.props.timezone[0].location_timezone,
                      });
                    var startTime = moment(
                        utcToLocal(shift.clock_in ? shift.clock_in : shift.startTime)
                    ).format("hh:mm A");
                    var endTime = moment(
                        utcToLocal(shift.clock_out ? shift.clock_out : shift.endTime)
                    ).format("hh:mm A");
                    var timecard =  moment(clock_in).format("hh:mm A") + "-" +  moment(clock_out).format("hh:mm A")

                    var innerArray = [];
                    innerArray.push(
                        moment(utcDateToLocal(shift.currentDate)).locale('en-US').format("DD MMM YYYY")
                    );
                    innerArray.push(shift.role_name);
                    innerArray.push(this.state.showHideSettings.timecard ? timecard : "");
                    innerArray.push(this.state.showHideSettings.scheduled ? shift.total_scheduled_hrs : "");
                    innerArray.push(this.state.showHideSettings.actual ? shift.total_actual_hrs : "");
                    // innerArray.push(shift.sch_vs_paid_hrs);
                    // innerArray.push(shift.sch_vs_actual_hrs);
                    innerArray.push(this.state.showHideSettings.total_paid ? shift.total_paid_hrs : "");
                    innerArray.push(this.state.showHideSettings.unpaid_breaks ? shift.total_break_hrs : "");
                    innerArray.push(this.state.showHideSettings.issues ? shift.total_issues : "");
                    {
                    }
                    tableRows.push(innerArray);
                });
            }
        });

        const doc = new jsPDF("l", "mm", "a4");
        // doc.text("Skylinx East Tue Feb 9 - Tue Feb 16, 2021", 14, 15);
        doc.autoTable({
            theme: "plain",
            // pageBreak: "always",
            headStyles: {
                minCellHeight: 20,
                fontSize: 12,
                cellPadding: 3,
                fontStyle: "bold",
            },
            styles: {
                minCellHeight: 15,
                fontSize: 9,
                halign: "center",
                valign: "middle",
                fillColor: "#fefefe",
                textColor: "black",
                fontStyle: "normal",
            },
            margin: { top: 10 },
            head: [tableColumn],
            body: tableRows,
        });

        this.setState({ printModal: false });
        const date = Date().split(" ");
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        doc.save(`timesheets_${dateStr}.pdf`);
    };

    closeDownloadModal = () => {
        this.setState({
            downloadModal: false,
        });
    };

    openDownloadModal = () => {
        this.setState({
            downloadModal: true,
        });
    };

    downloadClick = () => {
        this.setState({
            downloadModal: false,
            payrollModal: true,
        });
    };

    closePayrollModal = () => {
        this.setState({
            payrollModal: false,
        });
    };

    openTimecardModal = () => {
        var currentDate = moment(utcToLocal()).format("YYYY-MM-DD HH:mm:ss")
        // var currentDate = 
        var myItem = {
            uid: 0,
            lid: this.props.scheduler_lid,
            role_id: 0,
            currentDate: currentDate,
            shift_notes: "",
            clock_in: moment(this.state.myItem.currentDate).format("YYYY-MM-DD HH:mm:ss "),
            clock_out: moment(this.state.myItem.currentDate).format("YYYY-MM-DD HH:mm:ss"),
            allBreaks: [],
        };

        this.setState({
            myItem,
            isEdit: "ADD_TIMECARD",
            timecardModal: true,
            timecardStep: 0,
        });

    };

    closeTimecardModal = () => {
        this.setState({
            timecardModal: false,
            nextDay: false
        });
    };


    openViewTimecardModal = (item) => {
        var job_id = item.job_id;
        var myArray = [];
        this.state.usersData.map((user) => {
            user.myShifts.map((shift) => {
                myArray.push(shift.job_id);
            });
        });
        var myIndex = myArray.indexOf(job_id);

        var myItem = {
            job_id: job_id,
            // uid: uid,
            lid: this.props.scheduler_lid,
            role_id: item.role_id,
            currentDate: item.currentDate,
            shift_notes: item.shift_notes,
            clock_in: "",
            clock_out: "",
            allBreaks: [],
        };

        this.setState({
            isGeo: 0,
            myArray,
            myIndex,
            myItem,
            viewModal: true,
            isEdit: "VIEW_TIMECARD",
            timecardLoading: true,
        });

        timesheetServices.viewTimecard(myItem).then((response) => {
            var shiftDetails = response.data.shiftDetails;

            var actual_diff =
                parseFloat(shiftDetails.total_actual_hrs) -
                parseFloat(shiftDetails.total_scheduled_hrs);
            var break_diff = parseFloat(shiftDetails.total_break_hrs)

            myItem.uid = shiftDetails.uid;
            myItem.timecode = shiftDetails.timecode;
            var startTiime = new Date(shiftDetails.startTime)
            var endTiime = new Date(shiftDetails.endTime)
            var startTimeee = startTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            var endTimeee = endTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            myItem.startTime = startTimeee
            // myItem.endTime = moment(utcToLocal(shiftDetails.endTime)).format(
            //     "YYYY-MM-DD HH:mm:ss"
            // );
            myItem.endTime = endTimeee
            var clockIn = new Date(shiftDetails.clock_in)
            var clockOut = new Date(shiftDetails.clock_out)
            var clockInn = clockIn.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            var clockOutt = clockOut.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            // myItem.clock_in = shiftDetails.clock_in
            //     ? moment(utcToLocal(shiftDetails.clock_in)).format(
            //         "YYYY-MM-DD HH:mm:ss"
            //     )
            //     : null;
            myItem.clock_in = shiftDetails.clock_in
                ? clockInn
                : null;
            myItem.clock_out = shiftDetails.clock_out
                ? clockOutt
                : null;
            var RevisedclockIn = new Date(shiftDetails.revised_clock_in)
            var RevisedclockOut = new Date(shiftDetails.revised_clock_out)
            var RevisedclockInn = RevisedclockIn.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            var RevisedclockOutt = RevisedclockOut.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
            if (moment(RevisedclockOutt).format("HH:mm:ss") < moment(RevisedclockInn).format("HH:mm:ss")) {
                this.setState({
                    nextDay: true
                })
            } else {
                this.setState({
                    nextDay: false
                })
            }
            // if(RevisedclockInn && RevisedclockOutt){
            //     var startTime = moment(RevisedclockInn).format("YYYY-MM-DD HH:mm ")
            //     var endTime = moment(RevisedclockOutt).format("YYYY-MM-DD HH:mm ")
            //     var duration = moment.duration(moment(endTime).diff(moment(startTime)))
            //     var minitus = duration.asMinutes()
            //     var hours = Math.floor(minitus / 60);
            //     var tempMinites111 = minitus % 60;
            //     if(hours){
            //         myItem["total_manual_hrs"] = hours + "." + tempMinites111   
            //     }
            //     myItem["total_manual_hrs"] = myItem.total_manual_hrs 
            // }
            myItem.revised_clock_in = shiftDetails.revised_clock_in
                ? RevisedclockInn
                : null;
            myItem.revised_clock_in_old = shiftDetails.revised_clock_in
                ? RevisedclockInn
                : null;
            myItem.revised_clock_out = shiftDetails.revised_clock_out
                ? RevisedclockOutt
                : null;
            myItem.revised_clock_out_old = shiftDetails.revised_clock_out
                ? RevisedclockOutt
                : null;
            myItem.payroll_name = shiftDetails.payroll_name;
            myItem.rate = shiftDetails.rate;
            myItem.payroll_type = shiftDetails.payroll_type;
            myItem.payroll_rule = shiftDetails.payroll_rule;
            myItem.total_paid_hrs = shiftDetails.total_paid_hrs;
            myItem.total_actual_hrs = shiftDetails.total_actual_hrs;
            myItem.is_revised_approved = shiftDetails.is_revised_approved;
            myItem.is_break_approved = shiftDetails.is_break_approved;
            myItem.total_scheduled_hrs = shiftDetails.total_scheduled_hrs;
            myItem.total_manual_hrs = shiftDetails.total_manual_hrs;
            myItem.isDeleted=shiftDetails.isDeleted;
            myItem.total_break_hrs = shiftDetails.total_break_hrs;
            myItem.est_wage = shiftDetails.est_wage;
            myItem.is_approved = shiftDetails.is_approved;
            myItem.breakRule = shiftDetails.breakRule;

            var newBreaks = shiftDetails.allBreaks && shiftDetails.allBreaks.map((breakItem) => {
                var cloneItem = breakItem;
                var BreakStartTimee = new Date(breakItem.break_start)
                var BreakEndTimee = new Date(breakItem.break_end)
                var BreakStartTime = BreakStartTimee.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                var BreakEndTime = BreakEndTimee.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                cloneItem["break_start"] = breakItem.break_start
                    ? BreakStartTime
                    : null;
                cloneItem["break_end"] = breakItem.break_end
                    ? BreakEndTime
                    : null;
                return cloneItem;
            });

            myItem.break_diff = parseFloat(break_diff).toFixed(2);
            myItem.actual_diff = parseFloat(actual_diff).toFixed(2);
            myItem.allBreaks = newBreaks;
            myItem.allIssues = shiftDetails.allIssues;
            myItem.allActivity = shiftDetails.allActivity;
            myItem.allSelfie = shiftDetails.allSelfie;
            myItem.geoLocations = shiftDetails.geoLocations;
            myItem.break_rule_minites = shiftDetails.break_rule_minites;
            myItem.break_break_hours = shiftDetails.break_break_hours;
            myItem.isManual = shiftDetails.isManual;
            myItem.isScheduled = shiftDetails.isScheduled;
            this.setState({
                allBreaksOld: newBreaks,
                myItem,
                timecardLoading: false,
                display_timecard: 1,
                isTimecardEdit: 0,
            });
        });
    };

    closeViewTimecardModal = (item) => {
        this.setState({
            isEdit: 0,
            viewModal: false,
        });
    };

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                [state]: value,
            },
        }), () => {
        });
    };

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [state]: false,
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: "",
            },
        }));
    };

    handleChangeDateTime = (date, state) => {
        var clock_in_out = false
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                [state]: date.$d
            },
            nextDay: false
        }), () => {
            clock_in_out = false
            var myItem = this.state.myItem
            
            if (state === "revised_clock_in" || state === "revised_clock_out") {
                if (moment(this.state.myItem.revised_clock_out).format("HH:mm:ss") < moment(this.state.myItem.revised_clock_in).format("HH:mm:ss")) {
                    var date = new Date(myItem.currentDate);
                    date.setDate(date.getDate() + 1)
                    myItem["revised_clock_out"] = moment(date).format("YYYY-MM-DD") + "T" + moment(myItem.revised_clock_out ? myItem.revised_clock_out : myItem.clock_out).format("HH:mm:ss")
                    myItem["revised_clock_in"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in).format("HH:mm:ss")
                    this.setState({
                        nextDay: true
                    })
                } else {
                    myItem["revised_clock_out"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.revised_clock_out ? myItem.revised_clock_out : myItem.clock_out).format("HH:mm:ss")
                    myItem["revised_clock_in"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in).format("HH:mm:ss")
                    this.setState({
                        nextDay: false
                    })
                }

            } else {
                if (moment(this.state.myItem.clock_out).format("HH:mm:ss") < moment(this.state.myItem.clock_in).format("HH:mm:ss")) {
                    var date = new Date(myItem.currentDate);
                    date.setDate(date.getDate() + 1)
                    myItem["clock_out"] = moment(date).format("YYYY-MM-DD") + "T" + moment(myItem.clock_out).format("HH:mm:ss")
                    myItem["clock_in"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.clock_in).format("HH:mm:ss")
                    this.setState({
                        nextDay: true
                    })
                } else {
                    myItem["clock_out"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.clock_out).format("HH:mm:ss")
                    myItem["clock_in"] = moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.clock_in).format("HH:mm:ss")
                    this.setState({
                        nextDay: false
                    })
                }
            }
            // if(myItem.revised_clock_in && myItem.revised_clock_out){
            //     var startTime = moment(myItem.revised_clock_in).format("YYYY-MM-DD HH:mm ")
            //     var endTime = moment(myItem.revised_clock_out).format("YYYY-MM-DD HH:mm ")
            //     var duration = moment.duration(moment(endTime).diff(moment(startTime)))
            //     var minitus = duration.asMinutes()
            //     var hours = Math.floor(minitus / 60);
            //     var tempMinites111 = minitus % 60;
            //     if(hours){
            //         myItem["total_manual_hrs"] = hours + "." + tempMinites111   
            //     }
            //     myItem["total_manual_hrs"] = myItem.total_manual_hrs 
            // }
            // if( moment(this.state.myItem.clock_in).format("HH:mm:ss") < "12:00:00" &&  moment(this.state.myItem.clock_out).format("HH:mm:ss") >=  "12:00:00"){

            //     var date = new Date(moment(myItem.currentDate).format("YYYY-MM-DD"));
            //     date.setDate(date.getDate() + 1)
            //     myItem["clock_out"] =moment(date).format("YYYY-MM-DD") + "T" + moment(myItem.clock_out).format("HH:mm:ss")
            //     myItem["clock_in"] = moment(myItem.currentDate).format("YYYY-MM-DD")+ "T" + moment(myItem.clock_in).format("HH:mm:ss")     
            // }

            // if( moment(myItem.clock_in).format("HH:mm:ss A") > "12:00:00 AM" &&  moment(myItem.clock_out).format("HH:mm:ss A") >  "12:00:00 AM"){
            //     nextday = true
            //     var date = new Date(moment(myItem.currentDate).format("YYYY-MM-DD"));
            //     date.setDate(date.getDate() - 1)
            //     myItem["clock_out"] =nextday === true ?  moment(date).format("YYYY-MM-DD") + "T" + moment(myItem.clock_out).format("HH:mm:ss") : moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.clock_out).format("HH:mm:ss") 
            //     myItem["clock_in"] = nextday === true ? moment(myItem.currentDate).format("YYYY-MM-DD")+ "T" + moment(myItem.clock_in).format("HH:mm:ss") : moment(myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(myItem.clock_in).format("HH:mm:ss")     
            // }
            // if(new Date(this.state.myItem.clock_out) <  new Date(this.state.myItem.clock_in)){
            //     clock_in_out = true
            //     this.setState((prevState) => ({

            //         errors: {
            //             ...prevState.errors,
            //             clock_out: true,
            //         },
            //         errorsHelper: {
            //             ...prevState.errorsHelper,
            //             clock_out: "Please select valid date and time",
            //         },
            //     }));
            // }
            // if (clock_in_out) {
            //     return;
            // }

            this.setState({
                myItem
            })

        });
    };

    handleChangeDate = (date, state) => {
        var clock_in_out = false
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                [state]: date ? date : new Date().toLocaleString("en-US", { timeZone: this.props.timezone[0].location_timezone }),
            },
        }));
    };

    nextTimecard = () => {
        var tempItem = this.state.myItem;
        tempItem["clock_in"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD HH:mm:ss ")
        tempItem["clock_out"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD HH:mm:ss")

        if (this.state.myItem.uid === 0 || this.state.myItem.uid === "0") {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    uid: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    uid: "Please select user",
                },
            }));
        }

        if (this.state.myItem.role_id === 0 || this.state.myItem.role_id === "0") {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    role_id: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    role_id: "Please select role",
                },
            }));
        }

        if (
            this.state.myItem.uid === 0 ||
            this.state.myItem.uid === "0" ||
            this.state.myItem.role_id === 0 ||
            this.state.myItem.role_id === "0"
        ) {
            return;
        }

        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                myItem: tempItem
            },
            timecardStep: 1,
        }));
    };

    addBreakRow = () => {
        var breakObj = {
            _id: 0,
            break_type: 0,
            status: "add",
            break_start: moment(this.state.myItem.clock_in).format("YYYY-MM-DD") + "T" + moment(this.state.myItem.clock_in).format("HH:mm:ss"),
            break_end: moment(this.state.myItem.clock_out).format("YYYY-MM-DD") + "T" + moment(this.state.myItem.clock_out).format("HH:mm:ss"),
            // break_start:  moment(this.state.myItem.clock_in).format("YYYY-MM-DD")+ "T" + moment(this.state.myItem.clock_in).format("HH:mm:ss") + this.props.timezone[0].location_offset ,
            // break_end:  moment(this.state.myItem.clock_out).format("YYYY-MM-DD")+ "T" + moment(this.state.myItem.clock_out).format("HH:mm:ss") + this.props.timezone[0].location_offset,
        };
        var tempBreaks = this.state.myItem.allBreaks;
        tempBreaks.push(breakObj);
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                allBreaks: tempBreaks,
            },
        }));
    };

    deleteBreak = () => {
        var tempBreaks = this.state.myItem.allBreaks;
        tempBreaks.splice(this.state.indexBreak, 1);
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                allBreaks: tempBreaks,
            },
        }));
        this.deleteModalClose()
    }

    removeBreakRow = (index) => {
        this.setState({ deleteBreakModal: true, indexBreak: index })
    };

    deleteModalClose = () => {
        this.setState({
            deleteBreakModal: false
        })
    };

    handleFocusBreak = (e, index) => {
        var stateName = e.target.name;
        var tempBreaks = this.state.myItem.allBreaks;
        if (stateName === "break_start") {
            tempBreaks[index].break_start_error = false;
            tempBreaks[index].break_start_error_helper = "";
        } else {
            tempBreaks[index].break_end_error = false;
            tempBreaks[index].break_end_error_helper = false;
        }
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                allBreaks: tempBreaks,
            },
        }));
    };

    handleChangeBreak = (e, index) => {
        var tempBreaks = this.state.myItem.allBreaks;
        let value = e.target.value;
        var stateName = e.target.name;
        tempBreaks[index].add_edit_status = "edit";
        tempBreaks[index][stateName] = value;
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                allBreaks: tempBreaks,
            },
        }));
    };


    handleChangeBreakTime = (date, index, stateName) => {
        var breakError = false
        var start = this.state.myItem.clock_in;
        var end = this.state.myItem.clock_out;
        var todayDate = date;
        var tempBreaks = this.state.myItem.allBreaks;
        tempBreaks[index].add_edit_status = "add";
        tempBreaks[index][stateName] = date.$d;
        tempBreaks[index].isChanged = true;
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                allBreaks: tempBreaks,
            },
        }), () => {
            breakError = false
            var newBreaks = this.state.myItem.allBreaks?.map((breakItem) => {
                var cloneItem = breakItem;
                var check_next_day_shift = false;

                if (this.state.myItem?.revised_clock_in && this.state.myItem?.revised_clock_out) {
                    var revised_clock_in_date = parseInt(this.state.myItem?.revised_clock_in.split("/")[1]);
                    var revised_clock_out_date = parseInt(this.state.myItem?.revised_clock_out.split("/")[1]);
                    
                    if (revised_clock_in_date != revised_clock_out_date) {
                        check_next_day_shift = true;
                    }
                }
                else if (this.state.myItem?.clock_in && this.state.myItem?.clock_out) {
                    var clock_in_date = parseInt(this.state.myItem?.clock_in.split("/")[1]);
                    var clock_out_date = parseInt(this.state.myItem?.clock_out.split("/")[1]);
                    
                    if (clock_in_date != clock_out_date) {
                        check_next_day_shift = true;
                    }
                }
                else {
                    var startTime_date = parseInt(this.state.myItem?.startTime.split("/")[1]);
                    var endTime_date = parseInt(this.state.myItem?.endTime.split("/")[1]);
                    
                    if (startTime_date != endTime_date) {
                        check_next_day_shift = true;
                    }
                }

                if (check_next_day_shift) {
                    if (moment(cloneItem.break_end).format("HH:mm:ss") < moment(cloneItem.break_start).format("HH:mm:ss")) {
                        var date = new Date(this.state.myItem.currentDate);
                        date.setDate(date.getDate() + 1)
                        cloneItem["break_end"] = moment(date).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_end).format("HH:mm:ss")
                        cloneItem["break_start"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_start).format("HH:mm:ss")
                    } else {
                        var clockout_time = this.state.myItem?.revised_clock_out;

                        if (this.state.myItem?.revised_clock_in && this.state.myItem?.revised_clock_out) {
                            clockout_time = this.state.myItem?.revised_clock_out;
                        }
                        else if (this.state.myItem?.clock_in && this.state.myItem?.clock_out) {
                            clockout_time = this.state.myItem?.clock_out;
                        }
                        else {
                            clockout_time= this.state.myItem?.endTime;
                        }

                        if (moment(cloneItem.break_start).format("HH:mm:ss") >= "00:00:00" && moment(cloneItem.break_start).format("HH:mm:ss") < moment(clockout_time).format("HH:mm:ss")) {
                            var date = new Date(this.state.myItem.currentDate);
                            date.setDate(date.getDate() + 1)
                            cloneItem["break_end"] = moment(date).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_end).format("HH:mm:ss")
                            cloneItem["break_start"] = moment(date).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_start).format("HH:mm:ss")
                        } else {
                            cloneItem["break_end"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_end).format("HH:mm:ss")
                            cloneItem["break_start"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_start).format("HH:mm:ss")
                        }
                    }

                } else {
                    cloneItem["break_end"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_end).format("HH:mm:ss")
                    cloneItem["break_start"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(cloneItem.break_start).format("HH:mm:ss")
                }


                if (cloneItem.break_start === "" || cloneItem.break_start === null) {
                    breakError = true;
                    cloneItem["break_start_error"] = true;
                    cloneItem["break_start_error_helper"] = "Please select time";
                }
                if (cloneItem.break_end === "" || cloneItem.break_end === null) {
                    breakError = true;
                    cloneItem["break_end_error"] = true;
                    cloneItem["break_end_error_helper"] = "Please select time";
                }
                if (cloneItem.break_start && cloneItem.break_end) {
                    var starttime = this.state.myItem.clock_in;
                    var endtime = this.state.myItem.clock_out;


                    if (
                        this.state.myItem.revised_clock_in &&
                        this.state.myItem.revised_clock_out
                    ) {
                        starttime = this.state.myItem.revised_clock_in;
                        endtime = this.state.myItem.revised_clock_out;
                    }

                    starttime = new Date(starttime);
                    endtime = new Date(endtime)


                    var startbreakdate = new Date(cloneItem.break_start)
                    var endbreakdate = new Date(cloneItem.break_end)

                    // currentTime >= shiftStartDateTime && currentTime < breakStartDateTim

                    var inRange1 = startbreakdate >= starttime && startbreakdate < endtime

                    var inRange2 = endbreakdate >= starttime && endbreakdate < endtime

                    if (inRange1 === false) {
                        breakError = true;
                        cloneItem["break_start_error"] = true;
                        cloneItem["break_start_error_helper"] = "Please select valid time";
                    }
                    if (inRange2 === false) {
                        breakError = true;
                        cloneItem["break_end_error"] = true;
                        cloneItem["break_end_error_helper"] = "Please select valid time";
                    }
                    if (inRange1 === true && inRange2 === true) {
                        breakError = false
                        cloneItem["break_start_error"] = false;
                        cloneItem["break_end_error"] = false;

                    }

                }
                return cloneItem;

            });

            if (breakError || this.state.myItem.role_id === 0 || this.state.myItem.revised_clock_in === null || this.state.myItem.revised_clock_out === null) {
                return
            } else {
                breakError = false
                this.setState((prevState) => ({
                    myItem: {
                        ...prevState.myItem,
                        allBreaks: newBreaks,
                    }
                }))
            }

        })

        // if (
        //     this.state.myItem.revised_clock_in &&
        //     this.state.myItem.revised_clock_in
        // ) {
        //     start = this.state.myItem.revised_clock_in;
        //     end = this.state.myItem.revised_clock_out;
        // }
        // start = moment(moment(start)).format("YYYY-MM-DD HH:mm:ss");
        // end = moment(moment(end)).format("YYYY-MM-DD HH:mm:ss");
        // date = moment(moment(date.$d)).format("YYYY-MM-DD HH:mm:ss");
        // var startTime = moment(start).format("HH:mm:ss");
        // var endTime = moment(end).format("HH:mm:ss");
        // var dateTime = moment(date.$d).format("HH:mm:ss");


        // var ndDate = new Date(start);
        // ndDate.setDate(ndDate.getDate())
        // ndDate.setHours(endTime.split(":")[0]);
        // ndDate.setMinutes(endTime.split(":")[1]);
        // ndDate.setSeconds(endTime.split(":")[2]);

        // var nxtDate = moment(moment(ndDate)).format("YYYY-MM-DD HH:mm:ss");
        // if(startTime > endTime){
        //     ndDate = new Date(start);
        //     ndDate.setDate(ndDate.getDate() + 1)
        //     ndDate.setHours(endTime.split(":")[0]);
        //     ndDate.setMinutes(endTime.split(":")[1]);
        //     ndDate.setSeconds(endTime.split(":")[2]);
        //     // console.log("Next Day!! "+ndDate)
        //     nxtDate = moment(moment(ndDate)).format("YYYY-MM-DD HH:mm:ss");
        // } else {
        //     // console.log("Today!!!!!")
        // }

        // var brkDate = new Date(start);
        // brkDate.setDate(brkDate.getDate())
        // brkDate.setHours(dateTime.split(":")[0]);
        // brkDate.setMinutes(dateTime.split(":")[1]);
        // brkDate.setSeconds(dateTime.split(":")[2]); 

        // var breakDate = moment(moment(brkDate)).format("YYYY-MM-DD HH:mm:ss");
        // if(dateTime < startTime){
        //     brkDate = new Date(start);
        //     brkDate.setDate(brkDate.getDate() +1 )
        //     brkDate.setHours(dateTime.split(":")[0]);
        //     brkDate.setMinutes(dateTime.split(":")[1]);
        //     brkDate.setSeconds(dateTime.split(":")[2]); 
        //     breakDate = moment(moment(brkDate)).format("YYYY-MM-DD HH:mm:ss");
        // } else {            
        //     breakDate = moment(moment(brkDate)).format("YYYY-MM-DD HH:mm:ss");
        // }

        // // start = moment(start).format("HH:mm:ss");
        // // end = moment(end).format("HH:mm:ss");
        // // date = moment(date).format("HH:mm:ss");
        // //var inRange = (moment(date).isAfter(moment(start)) && moment(date).isBefore(moment(end)))
        // //var inRangedt = start < currDate && end > currDate
        // if(breakDate > start) {
        //     if(breakDate < nxtDate) {
        //         // console.log("currDate < end")
        //     }
        // }
        // var inRangedt = breakDate > start && breakDate < nxtDate
        // var currentDate = new Date()
        // var nextDate = new Date(currentDate + 1)

        // var startDate = new Date(currentDate.getTime());
        // startDate.setHours(start.split(":")[0]);
        // startDate.setMinutes(start.split(":")[1]);
        // startDate.setSeconds(start.split(":")[2]);

        // var newdate = new Date(currentDate.getTime());
        // newdate.setHours(date.split(":")[0]);
        // newdate.setMinutes(date.split(":")[1]);
        // newdate.setSeconds(date.split(":")[2]);

        // var endDate = new Date(nextDate.getTime()+1);
        // endDate.setHours(end.split(":")[0]);
        // endDate.setMinutes(end.split(":")[1]);
        // endDate.setSeconds(end.split(":")[2]);


        // var inRange = startDate < newdate && endDate > newdate
        
        //var inRange = moment(date).isBetween(start, end);

        // if (inRangedt) {
        //     var _idExist = this.state.myItem.allBreaks.map((value) => {
        //         return value._id
        //     })
        //     var tempBreaks = this.state.myItem.allBreaks;
        //     tempBreaks[index].add_edit_status = tempBreaks[index]._id != 0 ? "edit" : "add"
        //     tempBreaks[index][stateName] = date;
        //     this.setState((prevState) => ({
        //         myItem: {
        //             ...prevState.myItem,
        //             allBreaks: tempBreaks,
        //         },
        //     }), () => {
        //     });
        // }
    };

    handleSubmit = () => {
        var myItem = this.state.myItem;
        var breakError = false;
        if (myItem.role_id === 0) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    role_id: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    role_id: "Please select role",
                },
            }));
        }

        if (myItem.clock_in === null) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    clock_in: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    clock_in: "Please select clock_in",
                },
            }));
        }

        if (myItem.clock_out === null) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    clock_out: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    clock_out: "Please select clock_out",
                },
            }));
        }
        // var newdd = newBreaks.map((dd) => {
        //     // dd["break_start"] =  moment(dd.break_start).format("YYYY-MM-DD")+ "T" + moment(dd.break_start).format("HH:mm:ss") + this.props.timezone[0].location_offset;
        //     // dd["break_end"] =  moment(dd.break_end).format("YYYY-MM-DD")+ "T" + moment(dd.break_end).format("HH:mm:ss") + this.props.timezone[0].location_offset
        //     dd["break_start"] =  moment(dd.break_start).format("YYYY-MM-DD")+ "T" + moment(dd.break_start).format("HH:mm:ss") ;
        //     dd["break_end"] =  moment(dd.break_end).format("YYYY-MM-DD")+ "T" + moment(dd.break_end).format("HH:mm:ss") 
        //     return 
        // })
        myItem.currentDate = moment(myItem.currentDate).format("YYYY-MM-DD");
        // myItem.clock_in = moment(myItem.clock_in).format("YYYY-MM-DD")+ "T" + moment(myItem.clock_in).format("HH:mm:ss") + this.props.timezone[0].location_offset;
        // myItem.clock_out = moment(myItem.clock_out).format("YYYY-MM-DD")+ "T" + moment(myItem.clock_out).format("HH:mm:ss") + this.props.timezone[0].location_offset;
        myItem.clock_in = myItem.clock_in
        myItem.clock_out = myItem.clock_out
        myItem.startTime = myItem.clock_in;
        myItem.endTime = myItem.clock_out;
        myItem.allBreaks = myItem.allBreaks;
        this.setState({ timecardModal: false });
        this.addTimecard(myItem);
    };

    addTimecard = () => {
        var myItem = this.state.myItem;
        timesheetServices.addTimecard(myItem).then((response) => {
            if (response.data.success) {
                alert.success(response.data.message);
                this.fetchData(false);
            }
            else {
                alert.error(response.data.message)
            }
        });
    };

    deleteTimecard = (value) => {
        this.setState({ deleteTimecardModal: true, deleteData: value })
    };

    deleteTimecardClose = () => {
        this.setState({
            deleteTimecardModal: false
        })
    };

    deleteTimecardData = (value) => {
        var data = {
            job_id:this.state.deleteData.job_id,
            currentDate:moment(this.state.deleteData.currentDate).format("YYYY-MM-DD") 
        }
        timesheetServices.deleteTimecard(data).then((response) => {
            if (response.data.success) {
                var shift = "";
                var uid = "";
                this.state.usersData.map((user, index) => {
                    user.myShifts.map((item) => {
                        if (item.job_id === this.state.deleteData.job_id) {
                            shift = item;
                        }
                    });
                });
                alert.success(response.data.message);
                this.openViewTimecardModal(shift);
                this.fetchData(false);
                this.deleteTimecardClose()
            }
            else {
                alert.error(response.data.message)
            }
        });
    };

    toggleTimesheetIssue = (item) => {
        var data = {
            id: item._id,
            status: item.status ? 0 : 1,
        };
        timesheetServices.toggleTimesheetIssue(data).then((response) => {
            alert.success(response.data.message);
            let existed = this.state.myItem.allIssues.find(
                (item) => data.id === item._id
            );
            existed.status = data.isCompleted;

            this.setState((prevState) => ({
                myItem: {
                    ...prevState.myItem,
                    allIssues: this.state.myItem.allIssues.map((item) =>
                        item._id === existed.id ? { ...existed } : item
                    ),
                },
            }));
        });
    };

    handleSubmitEditTimecard = () => {
        var myData = this.state.myItem;
        var breakError = false;
        var newBreaks = myData.allBreaks?.map((breakItem) => {
            var cloneItem = breakItem;
            cloneItem["break_start_error"] = false;
            cloneItem["break_start_error_helper"] = "";
            cloneItem["break_end_error"] = false;
            cloneItem["break_end_error_helper"] = "";
        })
        var is_clockin = myData.clock_in;
        var is_clockout = myData.clock_out;
        if (myData.revised_clock_in && myData.revised_clock_out) {
            is_clockin = myData.revised_clock_in;
            is_clockout = myData.revised_clock_out;
        }

        if (myData.role_id === 0) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    role_id: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    role_id: "Please select role",
                },
            }));
        }

        if (is_clockin === null) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    revised_clock_in: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    revised_clock_in: "Please select clock_in",
                },
            }));
        }

        if (is_clockout === null) {
            this.setState((prevState) => ({
                errors: {
                    ...prevState.errors,
                    revised_clock_out: true,
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    revised_clock_out: "Please select clock_out",
                },
            }));
        }

        // var newBreaks = myData.allBreaks?.map((breakItem) => {
        //     var cloneItem = breakItem;
        //     if (breakItem.break_start === "" || breakItem.break_start === null) {
        //         breakError = true;
        //         cloneItem["break_start_error"] = true;
        //         cloneItem["break_start_error_helper"] = "Please select time";
        //     }
        //     if (breakItem.break_end === "" || breakItem.break_end === null) {
        //         breakError = true;
        //         cloneItem["break_end_error"] = true;
        //         cloneItem["break_end_error_helper"] = "Please select time";
        //     }
        //     if (breakItem.break_start && breakItem.break_end) {
        //         var starttime = myData.clock_in;
        //         var endtime = myData.clock_out;


        //         if (
        //             myData.revised_clock_in &&
        //             myData.revised_clock_out
        //         ) {
        //             starttime = myData.revised_clock_in;
        //             endtime = myData.revised_clock_out;
        //         }

        //         starttime =new Date(starttime);
        //         endtime = new Date(endtime)


        //          var startbreakdate = new Date(breakItem.break_start)
        //         var endbreakdate = new Date(breakItem.break_end)
        //         // currentTime >= shiftStartDateTime && currentTime < breakStartDateTim

        //         var inRange1 =  startbreakdate >= starttime && startbreakdate < endtime

        //         var inRange2 =   endbreakdate >= starttime && endbreakdate < endtime
        //         if (inRange1 === false) {
        //             breakError = true;
        //             cloneItem["break_start_error"] = true;
        //             cloneItem["break_start_error_helper"] = "Please select valid time";
        //         }
        //         if (inRange2 === false) {
        //             breakError = true;
        //             cloneItem["break_end_error"] = true;
        //             cloneItem["break_end_error_helper"] = "Please select valid time";
        //         }
        //         if(inRange1 === true && inRange2 === true){
        //             breakError= false
        //             cloneItem["break_start_error"] = false;
        //             cloneItem["break_end_error"] = false;

        //         }


        //         // if(moment(endbreakdate).format("HH:mm:ss") < moment(startbreakdate).format("HH:mm:ss")){
        //         //     var date = new Date(moment(this.state.myItem.currentDate).format("YYYY-MM-DD"));
        //         //     date.setDate(date.getDate() + 1)
        //         //     cloneItem["break_end"] =moment(date).format("YYYY-MM-DD") + "T" + moment(breakItem.break_end).format("HH:mm:ss")
        //         //     cloneItem["break_start"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD")+ "T" + moment(breakItem.break_start).format("HH:mm:ss") 

        //         // }else{
        //         //     cloneItem["break_end"] =moment(this.state.myItem.currentDate).format("YYYY-MM-DD") + "T" + moment(breakItem.break_end).format("HH:mm:ss")
        //         //     cloneItem["break_start"] = moment(this.state.myItem.currentDate).format("YYYY-MM-DD")+ "T" + moment(breakItem.break_start).format("HH:mm:ss") 

        //         // }
        //         // // myData["allBreaks"] = newBreaks
        //         // this.setState({
        //         //    myItem:myData
        //         // })

        //     }
        //     return cloneItem;
        // });

        if (breakError || myData.role_id === 0 || is_clockin === null || is_clockin === null) {
            return
        } else {
            breakError = false
            myData.allBreaks.forEach(mybreak => {
                if (mybreak.break_start.includes("/")) {
                    var break_start_date = moment(new Date(mybreak.break_start.split(", ")[0])).format("YYYY-MM-DD");
                    var break_start_time = moment(mybreak.break_start.split(", ")[1], ["h:mm A"]).format("HH:mm:ss");
                    mybreak["break_start"] = break_start_date + "T" + break_start_time;
                }
                if (mybreak.break_end.includes("/")) {
                    var break_end_date = moment(new Date(mybreak.break_end.split(", ")[0])).format("YYYY-MM-DD");
                    var break_end_time = moment(mybreak.break_end.split(", ")[1], ["h:mm A"]).format("HH:mm:ss");
                    mybreak["break_end"] = break_end_date + "T" + break_end_time;
                }
            })
            myData["allBreaks"] = myData.allBreaks

        }

        this.setState({ timecardLoading: true });

        var RevisedApproved = myData.revised_clock_in_old !== myData.revised_clock_in || myData.revised_clock_out_old !== myData.revised_clock_out ? 0 : myData.is_revised_approved
        var BreakApproved = JSON.stringify(this.state.allBreaksOld) !== JSON.stringify(myData.allBreaks) ? 0 : myData.is_break_approved
        var isApproved = BreakApproved && RevisedApproved ? 1 : myData.allBreaks.length === 0 && RevisedApproved ? 1 : 0

        var data = {
            job_id: myData.job_id,
            timecode: myData.timecode,
            is_revised_approved: RevisedApproved,
            is_approved: isApproved,
            currentDate: moment(myData.currentDate).format("YYYY-MM-DD"),
            // clock_in: myData.clock_in
            //     ?    moment(myData.clock_in).format("YYYY-MM-DD")+ "T" + moment(myData.clock_in).format("HH:mm:ss") + this.props.timezone[0].location_offset
            //     : null,
            // clock_out: myData.clock_out
            //     ?    moment( myData.clock_out).format("YYYY-MM-DD")+ "T" + moment( myData.clock_out).format("HH:mm:ss") + this.props.timezone[0].location_offset
            //     : null,
            // revised_clock_in: myData.revised_clock_in
            //     ? 
            //     moment(myData.revised_clock_in).format("YYYY-MM-DD")+ "T" + moment(myData.revised_clock_in).format("HH:mm:ss") + this.props.timezone[0].location_offset
            //     :  moment(myData.clock_in).format("YYYY-MM-DD")+ "T" + moment(myData.clock_in).format("HH:mm:ss") + this.props.timezone[0].location_offset,
            // revised_clock_out: myData.revised_clock_out
            //     ?    moment(myData.revised_clock_out).format("YYYY-MM-DD")+ "T" + moment(myData.revised_clock_out).format("HH:mm:ss") + this.props.timezone[0].location_offset
            //     :  moment( myData.clock_out).format("YYYY-MM-DD")+ "T" + moment( myData.clock_out).format("HH:mm:ss") + this.props.timezone[0].location_offset,
            clock_in: myData.clock_in
                ? moment(myData.clock_in).format("YYYY-MM-DD") + "T" + moment(myData.clock_in).format("HH:mm:ss")
                : null,
            clock_out: myData.clock_out
                ? moment(myData.clock_out).format("YYYY-MM-DD") + "T" + moment(myData.clock_out).format("HH:mm:ss")
                : null,
            revised_clock_in: myData.revised_clock_in
                ?
                moment(myData.revised_clock_in).format("YYYY-MM-DD") + "T" + moment(myData.revised_clock_in).format("HH:mm:ss")
                : moment(myData.clock_in).format("YYYY-MM-DD") + "T" + moment(myData.clock_in).format("HH:mm:ss"),
            revised_clock_out: myData.revised_clock_out
                ? moment(myData.revised_clock_out).format("YYYY-MM-DD") + "T" + moment(myData.revised_clock_out).format("HH:mm:ss")
                : moment(myData.clock_out).format("YYYY-MM-DD") + "T" + moment(myData.clock_out).format("HH:mm:ss"),
            allBreaks: myData.allBreaks,
        };
        
        this.setState({ timecardModal: false, errors: false });
        this.editTimecard(data);
    };

    editTimecard = (data) => {
        timesheetServices.editTimecard(data).then((response) => {
            var shift = "";
            var uid = "";
            this.state.usersData.map((user, index) => {
                user.myShifts.map((item) => {
                    if (item.job_id === data.job_id) {
                        shift = item;
                    }
                });
            });
            this.openViewTimecardModal(shift);
            this.fetchData(false);
            alert.success(response.data.message);
        });
    };

    approveBreaks = () => {
        var data = {
            job_id: this.state.myItem.job_id,
            is_break_approved: this.state.myItem.is_break_approved ? 0 : 1,
        };
        timesheetServices.approveBreaks(data).then((response) => {
            alert.success(response.data.message);
            this.fetchDataNew(false);
            var shift = "";
            this.state.usersData.map((user, index) => {
                user.myShifts.map((item) => {
                    if (item.job_id === this.state.myItem.job_id) {
                        shift = item;
                    }
                });
            });
            this.openViewTimecardModal(shift);
            var tempusers = this.state.usersData.map((user, index) => {
                var cloneItem = user;
                if (user._id === this.state.myItem.uid) {
                    user.myShifts.map((shift) => {
                        var cloneItem2 = shift;
                        if (shift.job_id === this.state.myItem.job_id) {
                            cloneItem2["is_break_approved"] = data.is_break_approved;
                        }
                        return cloneItem2;
                    });
                }
                return cloneItem;
            });

            this.setState((prevState) => ({
                myItem: {
                    ...prevState.myItem,
                    is_approved: response.data.is_approved,
                    is_break_approved: data.is_break_approved,
                },
                usersData: tempusers,
            }));
        });
    };

    approveRevisedHours = (data) => {
        var BreakApproved = data === "Breaks" ? this.state.myItem.is_break_approved ? 0 : 1 : this.state.myItem.is_break_approved
        var RevisedApproved = data === "Revised" ? this.state.myItem.is_revised_approved ? 0 : 1 : this.state.myItem.is_revised_approved
        var isApproved = BreakApproved && RevisedApproved ? 1 : this.state.myItem.allBreaks.length === 0 && RevisedApproved ? 1 : 0
        var data = {
            job_id: this.state.myItem.job_id,
            is_break_approved: BreakApproved,
            is_approved: isApproved,
            is_revised_approved: RevisedApproved,
            date: moment(utcToLocal(this.state.myItem.currentDate)).format(
                "YYYY-MM-DD"
            ),
            approve_type: data === "Revised" ? "shift_approval" : "break_approval"
        };
        timesheetServices.approveRevisedHours(data).then((response) => {
            alert.success(response.data.message);
            this.fetchDataNew(false);
            var shift = "";
            this.state.usersData.map((user, index) => {
                user.myShifts.map((item) => {
                    if (item.job_id === this.state.myItem.job_id) {
                        shift = item;
                    }
                });
            });
            this.openViewTimecardModal(shift);
            var tempusers = this.state.usersData.map((user, index) => {
                var cloneItem = user;
                if (user._id === this.state.myItem.uid) {
                    user.myShifts.map((shift) => {
                        var cloneItem2 = shift;
                        if (shift.job_id === this.state.myItem.job_id) {
                            cloneItem2["is_revised_approved"] = data.is_revised_approved;
                            cloneItem2["is_break_approved"] = data.is_break_approved;
                        }
                        return cloneItem2;
                    });
                }
                return cloneItem;
            });


            this.setState((prevState) => ({
                myItem: {
                    ...prevState.myItem,
                    is_approved: response.data.is_approved,
                    is_revised_approved: data.is_revised_approved,
                    is_break_approved: data.is_break_approved,
                },
                usersData: tempusers,
            }));
        });
    };

    handlePopoverOpen = (event) => {
        this.setState({ popoverAnchor: event.currentTarget });
    };

    handlePopoverClose = (value) => {
        this.setState({
            popoverAnchor: null,
        });
    };

    handleFilterPopoverOpen = (event) => {
        this.setState({ popoverFilterAnchor: event.currentTarget });
    };

    applyFilter = (filteremployee_uid, filtergroup_by, filtersort_by, filters,status_is_approved) => {
        var filters = {
            lid: this.props.scheduler_lid,
            filter_sort_by: filtersort_by ? filtersort_by : "name",
            filter_group_by: filtergroup_by ? filtergroup_by : "employee",
            filter_employee_uid: filteremployee_uid ? filteremployee_uid : "0",
            status_is_approved:status_is_approved ? status_is_approved : "4",
            payPeriod: this.state.payPeriod,
            startDate: this.state.custom_startDate,
            endDate: this.state.custom_endDate,
            Hide_Employees_With_No_Hours: filters.Hide_Employees_With_No_Hours,
            Hide_Employees_With_No_Timecard: filters.Hide_Employees_With_No_Timecard,
            Display_Edited_Timecard_Only: filters.Display_Edited_Timecard_Only,
            Display_Unscheduled_Shifts_Only: filters.Display_Unscheduled_Shifts_Only,
            Display_Scheduled_Shifts_Only: filters.Display_Scheduled_Shifts_Only
        }
        this.setState(
            {
                popoverFilterAnchor: null,
                filteremployee_uid,
                filtergroup_by,
                status_is_approved,
                filtersort_by,
            },
            () => {
                this.props.fetchDataNew(filters);
            }
        );
    };

    resetFilter = () => {
        var resetFilterss = {
            lid: this.props.scheduler_lid,
            payPeriod: this.state.payPeriod,
            isReset: 1
        }
        this.setState(
            {
                popoverFilterAnchor: null,
                filteremployee_uid: 0,
                filtergroup_by: "employee",
                status_is_approved:"4",
                filtersort_by: "name"
            },
            () => {
                this.props.fetchData(resetFilterss);
            }
        );
    };

    handleFilterPopoverClose = (value) => {
        this.setState({
            popoverFilterAnchor: null,
        });
    };

    selectPayPeriod = (value) => {
        this.setState({
            payPeriod: value,
        });
    };

    handleActiveTab = (activeTab) => {
        this.setState({
            activeTab,
        });
    };

    toggleViewTimecard = () => {
        this.setState({
            display_timecard: !this.state.display_timecard,
        });
    };

    approveWholetimecard = (item) =>{
        this.setState({
            approveLoading:true
        })
        var data ={
            job_id: this.state.myItem.job_id, 
            is_approved: item === "Unapprove" ? 0 : 1,
            date: moment(utcToLocal(this.state.myItem.currentDate)).format(
                "YYYY-MM-DD"
            ),
            approve_type: "shift_approval",
        }
        timesheetServices.approveWholetimecard(data).then((response) => {
            if(response.data?.success === 0){
                alert.error(response.data?.message); 
            }else{
                alert.success(response.data?.message);
            }
            this.setState({
                approveLoading:false
            });
            this.fetchDataNew(false);
            var shift = "";
            this.state.usersData.map((user, index) => {
                user.myShifts.map((item) => {
                    if (item.job_id === this.state.myItem.job_id) {
                        shift = item;
                    }
                });
            });
            this.openViewTimecardModal(shift);
        })
        .catch(error => {
            console.log("error", error?.response?.data);
            alert.error("Something went wrong. Please try again.");
            
            this.setState({
                approveLoading:false
            });
            this.fetchDataNew(false);
            var shift = "";
            this.state.usersData.map((user, index) => {
                user.myShifts.map((item) => {
                    if (item.job_id === this.state.myItem.job_id) {
                        shift = item;
                    }
                });
            });
            this.openViewTimecardModal(shift);
        });
    }



    handleActiveGeo = (isGeo) => {
        this.setState({
            isGeo,
        });
    };

    redictToMap = (item) => {
        if (item.latitude && item.longitude) {
            var path = `https://www.google.com/maps/search/?api=1&query=${item.latitude}%2C${item.longitude}&zoom=18`;
            window.open(path, "_blank");
        }
    };

    onSelectStartEndDate = (dates) => {
        const [start, end] = dates;
        // setStartDate(start);
        // setEndDate(end);
    };

    onSelectStartEndDate = (value, states) => {
        this.setState({
            custom_startDate: moment(value.start).format("YYYY-MM-DD"),
            custom_endDate: moment(value.end).format("YYYY-MM-DD"),
        });

        // this.setState({ value, states });
    };


    // onSelectStartEndDate = (value, states) => {
    //     let newLe = value.toString()
    //     let newLe2 = newLe.split(',')
    //     this.setState({
    //         custom_startDate: moment(newLe2[0]).format("YYYY-MM-DD"),
    //         custom_endDate: moment(newLe2[1]).format("YYYY-MM-DD"),
    //     },() =>{
    //         console.log("valueeee startcustom",this.state.custom_endDate,this.state.custom_startDate)
    //     });

    //     // this.setState({ value, states });
    // };

    // onChangeStartDate = (date) => {
    //     this.setState({ custom_startDate: date })
    // }

    // onChangeEndDate = (date) => {
    //     this.setState({ custom_endDate: date })
    // }

    applyPayPeriod = () => {
        var data = {
            lid: this.props.scheduler_lid,
            startDate: this.state.payPeriod === "today" ? this.state.todayDate : this.state.custom_startDate,
            endDate: this.state.custom_endDate,
            lid: this.props.scheduler_lid,
            filter_sort_by: this.state.filtersort_by ? this.state.filtersort_by : "name",
            filter_group_by: this.state.filtergroup_by ? this.state.filtergroup_by : "employee",
            filter_employee_uid: this.state.filteremployee_uid ? this.state.filteremployee_uid : "0",
            status_is_approved:this.state.status_is_approved ? this.state.status_is_approved : "4",
            payPeriod: this.state.payPeriod,
            Hide_Employees_With_No_Hours: this.state.hideshowfilters.Hide_Employees_With_No_Hours,
            Hide_Employees_With_No_Timecard: this.state.hideshowfilters.Hide_Employees_With_No_Timecard,
            Display_Edited_Timecard_Only: this.state.hideshowfilters.Display_Edited_Timecard_Only,
            Display_Unscheduled_Shifts_Only: this.state.hideshowfilters.Display_Unscheduled_Shifts_Only,
            Display_Scheduled_Shifts_Only: this.state.hideshowfilters.Display_Scheduled_Shifts_Only
        }
        if (this.state.payPeriod === "custom" || this.state.payPeriod === "today" || this.state.payPeriod === "previous") {
            this.props.fetchData(data);
        } else {
            this.props.fetchData(data)
        }
        this.setState({
            payPeriod: this.state.payPeriod,
            popoverAnchor: null,
        });
    };

    setTimecardEdit = () => {
        this.setState({
            isTimecardEdit: !this.state.isTimecardEdit,
        }, () => {
            if (this.state.isTimecardEdit === false) {
                var shift = "";;
                this.state.usersData.map((user, index) => {
                    user.myShifts.map((item) => {
                        if (item.job_id === this.state.myItem.job_id) {
                            shift = item;
                        }
                    });
                });
                this.openViewTimecardModal(shift);
                this.fetchData(false);
            }
            else {
                var item = this.state.myItem;
                item.revised_clock_in = item.revised_clock_in ? item.revised_clock_in : item.clock_in;
                item.revised_clock_out = item.revised_clock_out ? item.revised_clock_out : item.clock_out;
                this.setState({
                    myItem: item
                });
            }
        });


        this.state.myItem.allBreaks?.map((breakItem) => {
            var cloneItem = breakItem;
            cloneItem["break_start_error"] = false;
            cloneItem["break_start_error_helper"] = "";
            cloneItem["break_end_error"] = false;
            cloneItem["break_end_error_helper"] = "";
        })
    };

    nextTimecardClick = () => {
        var myIndex = this.state.myIndex + 1;
        var job_id = this.state.myArray[myIndex];
        this.setState(
            {
                myIndex,
            },
            () => {
                var shift = "";
                this.state.usersData.map((user, index) => {
                    user.myShifts.map((item) => {
                        if (item.job_id === job_id) {
                            shift = item;
                        }
                    });
                });
                this.openViewTimecardModal(shift);
            }
        );
    };

    previousTimecardClick = () => {
        var myIndex = this.state.myIndex - 1;
        var job_id = this.state.myArray[myIndex];
        this.setState(
            {
                myIndex,
            },
            () => {
                var shift = "";
                this.state.usersData.map((user, index) => {
                    user.myShifts.map((item) => {
                        if (item.job_id === job_id) {
                            shift = item;
                        }
                    });
                });
                this.openViewTimecardModal(shift);
            }
        );
    };

    handleChangeCheckbox = (e) => {
        let checked = e.target.checked;
        let name = e.target.name;
        this.setState({
            showHideSettings: {
                ...this.state.showHideSettings,
                [name]: checked ? 1 : 0
            }
        });
    }

    handleChangeFilterCheckbox = (e) => {
        let checked = e.target.checked;
        let name = e.target.name;
        this.setState({
            hideshowfilters: {
                ...this.state.hideshowfilters,
                [name]: checked ? 1 : 0
            }
        });
    }

    render() {
        const { classes, isLoading, permissionsData, teamUsers, timezone } = this.props;
        var user = this.state.usersData.find(
            (user) => this.state.myItem.uid === user._id
        );
        var role = this.props.rolesData.find(
            (role) => this.state.myItem.role_id === role._id
        );


        var userName = user ? user.user_name : "";
        var userImage = user ? user.user_image : "";
        var roleName = role ? role.role_name : "";
        if (isLoading) return <LoadingData />;
        if(this.state.approveLoading) return <LoadingData/>
        return permissionsData.scheduler_timesheet ? (
            <Grid container spacing={4} className={classes.root}
            >
                <Grid item sm={12} xs={12}>
                    <TimesheetToolbar
                        handleToggleDetails={this.handleToggleDetails}
                        is_expanded={this.state.is_expanded}
                        current_startDate={this.state.current_startDate}
                        current_endDate={this.state.current_endDate}
                        previous_startDate={this.state.previous_startDate}
                        previous_endDate={this.state.previous_endDate}
                        todayDate={this.state.todayDate}
                        custom_startDate={this.state.custom_startDate}
                        custom_endDate={this.state.custom_endDate}
                        payPeriod={this.state.payPeriod}
                        printModalOpen={this.printModalOpen}
                        permissionsData={permissionsData}
                        handleShowHideOpen={this.handleShowHideOpen}
                        handlePopoverOpen={this.handlePopoverOpen}
                        handleFilterPopoverOpen={this.handleFilterPopoverOpen}
                        toolbarData={this.state.toolbarData}
                        openDownloadModal={this.openDownloadModal}
                        openTimecardModal={this.openTimecardModal}
                        openViewTimecardModal={this.openViewTimecardModal}
                    />
                    {this.props.Display_Edited_Timecard_Only === 1 || this.props.Display_Unscheduled_Shifts_Only === 1 || this.props.Hide_Employees_With_No_Hours === 1 || this.props.Display_Scheduled_Shifts_Only === 1
                        || this.props.Hide_Employees_With_No_Timecard === 1 || this.props.filter_employee_uid !== "0" ?
                        <Grid container spacing={2} style={{ padding: "8px" }}>
                            <Grid item md={10} style={{ display: "flex", padding: "12px" }}>
                                <div style={{ color: "rgb(0, 95, 115)", padding: "3px", marginLeft: 10, fontWeight: 700 }}>
                                    Applied Filters
                                </div>
                                {this.props.Display_Edited_Timecard_Only === 1 &&
                                    <Grid item md={2} className={classes.myFilterButton}>
                                        {this.props.Display_Edited_Timecard_Only === 1 && "Edited Timecard Only"}
                                    </Grid>}
                                {this.props.Display_Unscheduled_Shifts_Only === 1 &&
                                    <Grid item md={2} className={classes.myFilterButton}>
                                        {this.props.Display_Unscheduled_Shifts_Only === 1 && "Unschedule Shift Only"}
                                    </Grid>}
                                {this.props.Display_Scheduled_Shifts_Only === 1 &&
                                    <Grid item md={2} className={classes.myFilterButton}>
                                        {this.props.Display_Scheduled_Shifts_Only === 1 && "schedule Shift Only"}
                                    </Grid>}
                                {this.props.Hide_Employees_With_No_Hours === 1 &&
                                    <Grid item md={2} className={classes.myFilterButton}>
                                        {this.props.Hide_Employees_With_No_Hours === 1 && "  Emp With no hours"}
                                    </Grid>}
                                {this.props.Hide_Employees_With_No_Timecard === 1 &&
                                    <Grid item md={2} className={classes.myFilterButton}>
                                        {this.props.Hide_Employees_With_No_Timecard === 1 && "Emp With no timecard"}
                                    </Grid>}
                                {this.props.filter_employee_uid !== "0" &&
                                    <Grid item md={2} style={{ border: "1px solid rgb(0, 95, 115)", padding: "4px", color: "rgb(0, 95, 115)", marginLeft: 10, textAlign: "center", height: 35 }}>
                                        {teamUsers.map((item) => {
                                            if (item.uid === this.props.filter_employee_uid) {
                                                return item.user_name
                                            }
                                        })}
                                    </Grid>}
                            </Grid>
                            <Grid item md={2} style={{ marginTop: 2 }}>
                                <Button
                                    style={{ float: "right", marginRight: "5px" }}
                                    onClick={this.resetFilter}
                                    size="small"
                                    variant="contained"
                                    className={classes.myButton}
                                >
                                    Reset All
                                </Button>
                            </Grid>
                        </Grid> : ""}



                    <ColleapseTable
                        showHideSettings={this.state.showHideSettings}
                        filter_sort_by={this.state.filtersort_by}
                        filter_group_by={this.state.filtergroup_by}
                        toolbarData={this.state.toolbarData}
                        usersData={this.state.usersData}
                        timezone={timezone}
                        openViewTimecardModal={this.openViewTimecardModal}
                        handleClickViewDetailsSingle={this.handleClickViewDetailsSingle}
                    />
                </Grid>


                <ShowHideMenus
                    handleShowHideClose={this.handleShowHideClose}
                    toolsAnchor={this.state.toolsAnchor}
                    showHideData={this.state.showHideData}
                    showHideSettings={this.state.showHideSettings}
                    toolbarSettings={this.state.toolbarSettings}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                />

                <MyPlain25Modal
                    bgColor={schedulerColor.main}
                    title="Print Schedule"
                    open={this.state.printModal}
                    onClose={this.printModalClose}
                >
                    <PrintModal
                        printData={this.printData}
                        printList={this.state.printList}
                    />
                </MyPlain25Modal>

                <DownloadModal
                    title="Download"
                    handleSubmit={this.downloadClick}
                    bgColor={schedulerColor.main}
                    open={this.state.downloadModal}
                    onClose={this.closeDownloadModal}
                // downloadClick = {this.downloadClick}
                />

                <FullModal
                    printPayrollSummary={this.printPayrollSummary}
                    bgColor={schedulerColor.main}
                    title="Timesheet"
                    open={this.state.payrollModal}
                    onClose={this.closePayrollModal}
                >
                    <PayrollTimesheet
                        onClose={this.closePayrollModal}
                        usersData={this.state.usersData}
                        isLoading={this.state.isLoading}
                        showHideSettings={this.state.showHideSettings}
                    />
                </FullModal>

                <DeleteModal
                    bgColor={schedulerColor.main}
                    desc="Are you sure you want to delete?"
                    open={this.state.deleteBreakModal}
                    onClose={this.deleteModalClose}
                    handleDelete={this.deleteBreak}
                />

                <DeleteModal
                    bgColor={schedulerColor.main}
                    desc="Are you sure you want to delete?"
                    open={this.state.deleteTimecardModal}
                    onClose={this.deleteTimecardClose}
                    handleDelete={this.deleteTimecardData}
                />

                <ViewModal
                    previousTimecardClick={this.previousTimecardClick}
                    nextTimecardClick={this.nextTimecardClick}
                    myIndex={this.state.myIndex}
                    myArray={this.state.myArray}
                    handleSubmitEditTimecard={this.handleSubmitEditTimecard}
                    setTimecardEdit={this.setTimecardEdit}
                    isTimecardEdit={this.state.isTimecardEdit}
                    myItem={this.state.myItem}
                    displayFooter={false}
                    timezone={timezone}
                    permissionsData={permissionsData}
                    timecardLoading={this.state.timecardLoading}
                    bgColor={schedulerColor.main}
                    open={this.state.viewModal}
                    onClose={this.closeViewTimecardModal}
                //handleSubmit={this.handleSubmit}
                >
                    <ViewTimecard
                        toggleTimesheetIssue={this.toggleTimesheetIssue}
                        timecodeData={this.state.timecodeData}
                        permissionsData={permissionsData}
                        setTimecardEdit={this.setTimecardEdit}
                        isTimecardEdit={this.state.isTimecardEdit}
                        roleName={roleName}
                        userName={userName}
                        timezone={timezone}
                        userImage={userImage}
                        nextDay={this.state.nextDay}
                        open={this.state.viewModal}
                        deleteTimecard={this.deleteTimecard}
                        currentDate={this.state.myItem.currentDate}
                        activeTab={this.state.activeTab}
                        isGeo={this.state.isGeo}
                        display_timecard={this.state.display_timecard}
                        usersData={this.state.usersData}
                        rolesData={this.props.rolesData}
                        myItem={this.state.myItem}
                        errors={this.state.errors}
                        errorsHelper={this.state.errorsHelper}
                        redictToMap={this.redictToMap}
                        toggleViewTimecard={this.toggleViewTimecard}
                        handleActiveGeo={this.handleActiveGeo}
                        handleActiveTab={this.handleActiveTab}
                        approveRevisedHours={this.approveRevisedHours}
                        approveBreaks={this.approveBreaks}
                        approveWholetimecard={this.approveWholetimecard}
                        handleChangeBreakTime={this.handleChangeBreakTime}
                        handleFocusBreak={this.handleFocusBreak}
                        handleChangeBreak={this.handleChangeBreak}
                        addBreakRow={this.addBreakRow}
                        removeBreakRow={this.removeBreakRow}
                        nextTimecard={this.nextTimecard}
                        handleSubmitEditTimecard={this.handleSubmitEditTimecard}
                        handleChangeDateTime={this.handleChangeDateTime}
                        handleChange={this.handleChange}
                        handleFocus={this.handleFocus}
                    />
                </ViewModal>

                <PayrollPopover
                    todayDate={this.state.todayDate}
                    current_startDate={this.state.current_startDate}
                    current_endDate={this.state.current_endDate}
                    previous_startDate={this.state.previous_startDate}
                    previous_endDate={this.state.previous_endDate}
                    custom_startDate={this.state.custom_startDate}
                    custom_endDate={this.state.custom_endDate}
                    payrollFilterData={this.state.payrollFilterData}
                    payPeriod={this.state.payPeriod}
                    onSelectStartEndDate={this.onSelectStartEndDate}
                    applyPayPeriod={this.applyPayPeriod}
                    selectPayPeriod={this.selectPayPeriod}
                    handlePopoverClose={this.handlePopoverClose}
                    anchorEl={this.state.popoverAnchor}
                />

                <FilterPopover
                    teamUsers={teamUsers}
                    filteremployee_uid={this.state.filteremployee_uid}
                    filtergroup_by={this.state.filtergroup_by}
                    filtersort_by={this.state.filtersort_by}
                    status_is_approved={this.state.status_is_approved}
                    applyFilter={this.applyFilter}
                    usersData={this.props.usersData}
                    resetFilter={this.resetFilter}
                    hideshowfilters={this.state.hideshowfilters}
                    handleFilterPopoverClose={this.handleFilterPopoverClose}
                    anchorEl={this.state.popoverFilterAnchor}
                    toolsAnchor={this.state.toolsAnchor}
                    toolsData={this.state.toolsData}
                    toolbarSettings={this.state.toolbarSettings}
                    handleChangeFilterCheckbox={this.handleChangeFilterCheckbox}
                // open={this.state.popoverFilterAnchor}
                />

                <AddModal
                    displayHeader={this.state.timecardStep === 1 ? true : false}
                    displayFooter={this.state.timecardStep === 1 ? true : false}
                    roleName={roleName}
                    userName={userName}
                    userImage={userImage}
                    currentDate={this.state.myItem.currentDate}
                    bgColor={schedulerColor.main}
                    open={this.state.timecardModal}
                    onClose={this.closeTimecardModal}
                    handleSubmit={this.handleSubmit}
                    handleChange={this.handleChange}
                    handleFocus={this.handleFocus}
                    myItem={this.state.myItem}

                // toolbarData={this.state.toolbarData}
                >
                    <AddTimecard
                        timecardStep={this.state.timecardStep}
                        usersData={this.state.usersData}
                        rolesData={this.props.rolesData}
                        myItem={this.state.myItem}
                        errors={this.state.errors}
                        timezone={timezone}
                        errorsHelper={this.state.errorsHelper}
                        handleChangeBreakTime={this.handleChangeBreakTime}
                        handleFocusBreak={this.handleFocusBreak}
                        handleChangeBreak={this.handleChangeBreak}
                        addBreakRow={this.addBreakRow}
                        removeBreakRow={this.removeBreakRow}
                        nextTimecard={this.nextTimecard}
                        nextDay={this.state.nextDay}
                        handleClose={this.closeTimecardModal}
                        handleChangeDateTime={this.handleChangeDateTime}
                        handleChangeDate={this.handleChangeDate}
                        handleChange={this.handleChange}
                        handleFocus={this.handleFocus}
                        handleSubmit={this.handleSubmit}
                        // open={this.nextTimecard}
                        openViewTimecardModal={this.openViewTimecardModal}

                    />
                </AddModal>
            </Grid>
        ) : (
            <Grid container spacing={2} className={classes.root}>
                <Grid item md={12} className={classes.buttonMain}>
                    <NoPermission />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    teamUsers: state.schedulerTeam.usersData,
    isLoading: state.schedulerTimsheets.isLoading,
    usersData: state.schedulerTimsheets.usersData,
    timecodeData: state.schedulerTimsheets.timecodeData,
    toolbarSettings: state.schedulerTimsheets.toolbarSettings,
    current_startDate: state.schedulerTimsheets.current_startDate,
    current_endDate: state.schedulerTimsheets.current_endDate,
    previous_startDate: state.schedulerTimsheets.previous_startDate,
    previous_endDate: state.schedulerTimsheets.previous_endDate,
    rolesData: state.schedulerRoles.rolesData,
    Display_Edited_Timecard_Only: state.schedulerTimsheets.Display_Edited_Timecard_Only,
    Display_Unscheduled_Shifts_Only: state.schedulerTimsheets.Display_Unscheduled_Shifts_Only,
    Display_Scheduled_Shifts_Only: state.schedulerTimsheets.Display_Scheduled_Shifts_Only,
    Hide_Employees_With_No_Hours: state.schedulerTimsheets.Hide_Employees_With_No_Hours,
    Hide_Employees_With_No_Timecard: state.schedulerTimsheets.Hide_Employees_With_No_Timecard,
    filter_employee_uid: state.schedulerTimsheets.filter_employee_uid,
    filter_group_by: state.schedulerTimsheets.filter_group_by,
    filter_sort_by: state.schedulerTimsheets.filter_sort_by,
    payPeriod: state.schedulerTimsheets.payPeriod,
    custom_startDate: state.schedulerTimsheets.custom_startDate,
    custom_endDate: state.schedulerTimsheets.custom_endDate
});

const ConnectWith = connect(mapStateToProps, {
    fetchTeamUsers,
    fetchData,
    fetchDataNew,
    fetchRolesData,
    fetchPayrollTimecode,
})(Timesheets);
export default withStyles(styles)(ConnectWith);

