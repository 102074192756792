import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { history } from "../../../../history";
import { ProfileSample } from "../../../../assets/images/mobileView/profile";
import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mobileTheme } from "../../constant";
import "./GradingHeader.scss";

const GradingHeader = ({ role, userName, LessonDetail, handleSlides }) => {
  return (
    <div className={"GradingHeaderRoot"} style={{ background: mobileTheme }}>
      <div className={"GradingHeaderLeftSection"}>
        <div onClick={() => history.push("/elearning/grading")}>
          <FaChevronLeft className={"GradingHeaderArrowIcon"} />
        </div>
        <div className={"GradingHeaderProfileContainer"}>
          <div>
            <img
              alt=""
              src={ProfileSample}
              className={"GradingHeaderProfileImage"}
            />
          </div>
          <div className={"GradingHeaderUserDetails"}>
            <Typography className={"GradingHeaderUserName"}>
              {userName}
            </Typography>
            <Typography className={"GradingHeaderRole"}>{role}</Typography>
            <Typography className={"GradingHeaderLessonDetail"}>
              Lesson: {LessonDetail?.lesson_name}
            </Typography>
          </div>
        </div>
      </div>

      <div className={"GradingHeaderRightSection"}>
        <Typography
          className={"GradingHeaderViewSlides"}
          onClick={handleSlides}
        >
          View Slides
        </Typography>
      </div>
    </div>
  );
};

export default GradingHeader;
