import React, { useEffect, useState } from "react";

// icons
// import { IoMdSearch } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { mobileTheme } from "../../constant";
import { Toggel } from "../../utilities/common/Button";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableRow,
  Stack,
  Pagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import { connect } from "react-redux";

// data
import { headMyGradingElements, headTeamGradingElements } from "./constant";

import "../style.css";
import {
  FailButton,
  GradientButton,
  PassButton,
  PendingButton,
} from "../../utilities/common/Button/buttonStyles";

import {
  // fetchData as fetchGrading,
  // fetchAssignLesson,
  // fetchlocationData,
  fetchRoleData,
  fetchTeamGradingData,
} from "../../../../pages/eLearning/Grading/modules/actions";

import {
  fetchData,
  getMyLessonNames,
} from "../../../../pages/eLearning/Progress/modules/actions";

// import Cookies from "universal-cookie";
import { history } from "../../../../history";
import { useGradingLessonDetail } from "../../context/gradingContext";
import { MobileLoader } from "../../utilities";

import "./GradingMobileList.scss";
import ReactSelect from "react-select";
import { NoPermission } from "../../../../components";

// const cookies = new Cookies();
// var ovUid = cookies.get("ovUid");

const generateFormControl = (onChange, value, items) => {
  return (
    <FormControl
      sx={{
        width: "80px",
        height: "26px",
        borderRadius: "5px!important",
        bgcolor: "#1B3F8F1A",
        // marginRight: "5px",
        "& .MuiSvgIcon-root": { color: "#1B3F8F" },
      }}
      size="small"
    >
      <Select
        IconComponent={() => <ExpandMoreIcon style={{ fontSize: "13px" }} />}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        sx={{
          borderRadius: "5px!important",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          border: "1px solid #1B3F8F1A",
          width: "80px",
          height: "26px",
          fontSize: "10px",
        }}
        onChange={onChange}
        value={value}
        displayEmpty
        // placeholder={placeholder}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{ fontSize: "8px" }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const generateSearchSelectControl = (onChange, value, items) => {
  return (
    <FormControl
      sx={{
        width: "100px",
        height: "26px",
        borderRadius: "5px!important",
        // bgcolor: "#1B3F8F1A",
        // marginRight: "5px",
        "& .MuiSvgIcon-root": { color: "#1B3F8F" },
      }}
      size="small"
    >
      {/* <Select
        IconComponent={() => <ExpandMoreIcon style={{ fontSize: "13px" }} />}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        sx={{
          borderRadius: "5px!important",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          border: "1px solid #1B3F8F1A",
          width: "80px",
          height: "26px",
          fontSize: "10px",
        }}
        onChange={onChange}
        value={value}
        displayEmpty
        // placeholder={placeholder}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{ fontSize: "8px" }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select> */}

      <ReactSelect
        options={items}
        // getOptionLabel={(option) => option.lesson_name}
        // getOptionValue={(option) => option._id}
        value={value}
        placeholder={
          items.find((item) => item.value === value)
            ? items.find((item) => item.value === value).label.slice(0, 8)
            : "Lesson"
        }
        onChange={onChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px solid #1B3F8F1A",
            borderRadius: "5px!important",
            width: "100px",
            minHeight: "26px",
            height: "26px",
            fontSize: "10px",
            background: "#1B3F8F1A",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: "#1B3F8F",
            display: "flex",
            alignItems: "center",
            height: "26px",
            width: "30px",
          }),
          menuPortal: (base) => ({
            ...base,
            fontSize: "12px",
          }),
        }}
      />
    </FormControl>
  );
};

const MobileGrading = ({
  handleManagerQuiz,
  handleVideoQuiz,
  // userID,
  fetchTeamGradingData,
  teamGradingPayload,
  isTeamGradingLoading,
  fetchData,
  getMyLessonNames,
  ProgressData,
  isProgressLoading,
  teamGradingPageNumber,
  handleTeamPageChange,
  myGradingTotalPages,
  handleView,
  // Filters //
  showHideSettings,
  handleChangeCheckbox,
  // lesson
  lesson_s,
  handleChangeDropDownLesson,
  isLessonNamesDataLoading,
  lessonNamesData,
  // Role
  fetchRoleData,
  isRolesLoading,
  roleDetails,
  selectedRole,
  handleChangeDropDownRole,
  // lesson quiz
  handleChangeDropDownLessonQuizStatus,
  lesson_quiz_s,
  // my lessons
  myLessonNamesData,
  isMyLessonNamesDataLoading,
  // manager quiz
  handleChangeDropDownManagerStatus,
  manager_quiz_s,
  // video quiz
  handleChangeDropDownVideo1,
  video_quiz_s,
  permissionsData,
}) => {
  const [selectedTab, setSelectedTab] = useState("my");
  const [myGradingPageNumber, setMyGradingPageNumber] = useState(1);

  const { setUserName, setRole } = useGradingLessonDetail();

  const handleTabChange = (value) => {
    setSelectedTab(value);
    localStorage.setItem("gradingToggelTabState", JSON.stringify(value));
  };

  useEffect(() => {
    if (
      !!permissionsData.elearning_grading_team_grading &&
      !permissionsData.elearning_grading_my_grading
    ) {
      setSelectedTab("team");
    } else {
      setSelectedTab("my");
    }
  }, [
    permissionsData.elearning_grading_my_grading,
    permissionsData.elearning_grading_team_grading,
  ]);

  useEffect(() => {
    const storedState = localStorage.getItem("gradingToggelTabState");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      // Use parsedState to set the state
      setSelectedTab(parsedState);
    }

    // fetchRoleData();
    // getMyLessonNames();
  }, [fetchRoleData]);

  useEffect(() => {
    if (selectedTab === "team") {
      let data = {
        page: 1,
        limit: 50,
      };
      fetchTeamGradingData(data);
    } else if (selectedTab === "my") {
      let data = {
        page: 1,
        limit: 50,
      };
      fetchData(data);
      getMyLessonNames();
    }
  }, [fetchData, fetchTeamGradingData, selectedTab]);

  const handleMyGradingPageChange = (e, value) => {
    setMyGradingPageNumber(value);
    let data = {
      page: value,
      limit: 50,
    };
    fetchData(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // My Grading Filter States
  const initialFilterState = {
    lessonName: "",
    lessonQuizStatus: "",
    videoStatus: "",
    managerStatus: "",
  };
  const [filters, setFilters] = useState(initialFilterState);

  // My Grading Filter functions
  const handleChangeFilter = (filter, value) => {
    setMyGradingPageNumber(1);
    const newData = { ...filters, [filter]: value };

    let data = {
      page: 1,
      limit: 50,
    };

    // Check if the value is truthy before setting it in the data object
    if (newData.lessonName) {
      data.lesson_id = newData.lessonName;
    }
    if (newData.videoStatus) {
      data.video_quiz_status = newData.videoStatus;
    }
    if (newData.lessonQuizStatus) {
      data.lesson_quiz_status = newData.lessonQuizStatus;
    }
    if (newData.managerStatus) {
      data.manager_quiz_status = newData.managerStatus;
    }

    setFilters(newData);
    fetchData(data);
  };

  const handleSubmitVideo = (e, _id, ecid, lesson_result_id) => {
    history.push(`/elearning/grading/video/${_id}/${lesson_result_id}`);
  };

  const handleStoreUserDetails = (userName, role) => {
    setUserName(userName);
    setRole(role);
  };

  const renderHeadRows = (data, showHideSettings) =>
    data.map((item, index) =>
      showHideSettings[item.heading.toLowerCase().replace(/\s/g, "_")] === 0 ? (
        ""
      ) : showHideSettings[item.heading.toLowerCase().replace(/\s/g, "_")] ===
          undefined || null ? (
        <TableCell key={item.id} className={"headCell"}>
          {item.heading}
        </TableCell>
      ) : (
        <TableCell key={item.id} className={"headCell"}>
          {item.heading}
        </TableCell>
      )
    );

  const renderRows = (data) => {
    return data.map((row, index) => (
      <TableRow key={row.id}>
        <TableCell className={"bodyCell"} component="th" scope="row">
          {row.lesson_name}
        </TableCell>
        {selectedTab === "team" ? (
          <TableCell className={"bodyCell"}>{row?.user?.user_name}</TableCell>
        ) : (
          ""
        )}

        {selectedTab === "team" ? (
          <TableCell className={"bodyCell"}>
            {row?.user_role?.role_name}
          </TableCell>
        ) : (
          ""
        )}

        {showHideSettings.lesson_quiz ? (
          <TableCell className={"bodyCell"}>{row.lesson_quiz_status}</TableCell>
        ) : (
          ""
        )}

        {showHideSettings.manager_quiz ? (
          <TableCell className={"bodyCell"}>
            {row.manager_quiz_status === "Pending" ||
            row.manager_quiz_status === "Reassigned" ? (
              row.lesson_result && selectedTab === "team" ? (
                <GradientButton
                  variant="contained"
                  value="Manager"
                  onClick={(e) => {
                    handleManagerQuiz(
                      e,
                      row._id,
                      row.ecid,
                      row.lesson_result._id,
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Grade
                </GradientButton>
              ) : (
                row.manager_quiz_status
              )
            ) : (
              row.manager_quiz_status
            )}
          </TableCell>
        ) : (
          ""
        )}

        {showHideSettings.video_quiz ? (
          <TableCell className={"bodyCell"}>
            {row.video_quiz_status === "Pending" ||
            row.video_quiz_status === "Reassigned" ? (
              row.lesson_result &&
              row.lesson_result.video_url &&
              selectedTab === "team" ? (
                <GradientButton
                  variant="contained"
                  value="Video"
                  onClick={(e) => {
                    handleVideoQuiz(
                      e,
                      row.user._id,
                      row._id,
                      row.ecid,
                      row.lesson_result._id,
                      row.lesson_result.video_url
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Grade
                </GradientButton>
              ) : row.lesson_result &&
                !row.lesson_result.video_url &&
                selectedTab === "my" ? (
                <GradientButton
                  variant="contained"
                  value="Video"
                  onClick={(e) => {
                    handleSubmitVideo(
                      e,
                      row._id,
                      row.ecid,
                      row.lesson_result._id
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Record
                </GradientButton>
              ) : (
                row.video_quiz_status
              )
            ) : (
              row.video_quiz_status
            )}
          </TableCell>
        ) : (
          ""
        )}

        <TableCell className={"bodyCell"}>
          {row.overall_status === "Pending" ? (
            <PendingButton variant="contained" disableElevation>
              Pending
            </PendingButton>
          ) : row.overall_status === "Pass" ? (
            <PassButton variant="contained" disableElevation>
              Pass
            </PassButton>
          ) : (
            <FailButton variant="contained" disableElevation>
              Fail
            </FailButton>
          )}
        </TableCell>

        <TableCell className={"bodyCell"}>
          <GradientButton
            variant="contained"
            onClick={(e) => handleView(e, row.ecid, row._id)}
          >
            View
          </GradientButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <div className={"GradingListRoot"}>
      <div className={"toggelContainer"}>
        <Toggel
          options={[
            !!permissionsData.elearning_grading_my_grading && {
              id: 1,
              heading: "my",
              value: "my",
            },
            !!permissionsData.elearning_grading_team_grading && {
              id: 2,
              heading: "team",
              value: "team",
            },
          ]}
          tabSize={{ width: "65px", height: "30px" }}
          selected={selectedTab}
          selectedBgColor={mobileTheme}
          size={{
            width:
              !!permissionsData.elearning_grading_my_grading &&
              !!permissionsData.elearning_grading_team_grading
                ? "130px"
                : "65px",
            height: "34px",
          }}
          bgColor={"white"}
          border={"1px solid rgba(27, 63, 143,1)"}
          borderImageSource={
            "linear-gradient(180deg, #1B3F8F 0%, #635BFF 100%)"
          }
          handleTabChange={handleTabChange}
        />
      </div>
      <Box
        display={"flex"}
        justifyContent={"space-evenly"}
        // mt={"3rem"}
        mx={"1rem"}
        mb={"1.5rem"}
      >
        <Box display={"flex"}>
          <Box
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            backgroundColor={"white"}
          >
            <Box>
              <FormControlLabel
                alignItems={"center"}
                // mb={"0rem"}
                style={{
                  // marginLeft: "0.5px",
                  marginBottom: "0rem",
                }}
                control={
                  <Checkbox
                    name={"lesson_quiz"}
                    sx={{
                      // padding: "6px",
                      marginLeft: "2px",
                      "&.Mui-checked": {
                        color: "#1B3F8F",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 15 },
                    }}
                    onClick={handleChangeCheckbox}
                    checked={showHideSettings.lesson_quiz === 1}
                  />
                }
                label={
                  <Typography className={"checkBoxText"}>
                    Lesson Quiz
                  </Typography>
                }
              />{" "}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            borderLeft={"none"}
            backgroundColor={"white"}
          >
            <FormControlLabel
              alignItems={"center"}
              style={{
                // marginLeft: "0.5px",
                marginBottom: "0rem",
              }}
              control={
                <Checkbox
                  name={"video_quiz"}
                  sx={{
                    // padding: "6px",
                    marginLeft: "2px",
                    "&.Mui-checked": {
                      color: "#1B3F8F",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 15 },
                  }}
                  onClick={handleChangeCheckbox}
                  checked={showHideSettings.video_quiz === 1}
                />
              }
              label={
                <Typography className={"checkBoxText"}>Video Quiz</Typography>
              }
            />{" "}
          </Box>
          <Box
            style={{
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            borderLeft={"none"}
            backgroundColor={"white"}
          >
            <FormControlLabel
              alignItems={"center"}
              style={{
                // marginLeft: "0.5px",
                marginBottom: "0rem",
              }}
              control={
                <Checkbox
                  name={"manager_quiz"}
                  sx={{
                    // padding: "6px",
                    marginLeft: "2px",
                    "&.Mui-checked": {
                      color: "#1B3F8F",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 15 },
                  }}
                  onClick={handleChangeCheckbox}
                  checked={showHideSettings.manager_quiz === 1}
                />
              }
              label={
                <Typography className={"checkBoxText"}>Manager Quiz</Typography>
              }
            />{" "}
          </Box>
        </Box>
      </Box>
      <div className="dropdownsContainer">
        {selectedTab === "team" &&
          // !isRolesLoading &&
          Array.isArray(roleDetails) &&
          generateFormControl(
            (event) => handleChangeDropDownRole(event),
            selectedRole,
            [
              { value: "", label: "All Role" },
              ...roleDetails.map((item) => ({
                value: item._id,
                label: item?.role_name,
              })),
            ]
          )}

        {selectedTab === "my"
          ? generateSearchSelectControl(
              (event) => handleChangeFilter("lessonName", event.value),
              filters["lessonName"],
              [
                { value: "", label: "All Lessons" },
                ...myLessonNamesData.map((item) => ({
                  value: item._id,
                  label: item?.lesson_name,
                })),
              ]
            )
          : generateSearchSelectControl(
              (event) => handleChangeDropDownLesson(event),
              lesson_s,
              [
                { value: "", label: "All Lessons" },
                ...lessonNamesData.map((item) => ({
                  value: item._id,
                  label: item?.lesson_name,
                })),
              ]
            )}
        {generateFormControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("lessonQuizStatus", event.target.value)
              : handleChangeDropDownLessonQuizStatus(event),
          selectedTab === "my" ? filters["lessonQuizStatus"] : lesson_quiz_s,
          [
            { value: "", label: "Lesson Quiz" },
            { value: "Passed", label: "Passed" },
            { value: "Pending", label: "Pending" },
          ]
        )}
        {generateFormControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("managerStatus", event.target.value)
              : handleChangeDropDownManagerStatus(event),
          selectedTab === "my" ? filters["managerStatus"] : manager_quiz_s,
          [
            { value: "", label: "Manager Quiz" },
            { value: "Reassigned", label: "Reassigned" },
            { value: "Pass", label: "Pass" },
            { value: "Pending", label: "Pending" },
          ]
        )}
        {generateFormControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("videoStatus", event.target.value)
              : handleChangeDropDownVideo1(event),
          selectedTab === "my" ? filters["videoStatus"] : video_quiz_s,
          [
            { value: "", label: "Video Quiz" },
            { value: "Pending", label: "Pending" },
            { value: "Pass", label: "Pass" },
            { value: "Reassigned", label: "Reassigned" },
          ]
        )}
      </div>
      <TableContainer className={`scroll tableContainer`}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {selectedTab === "my"
                ? permissionsData?.elearning_grading_my_grading
                  ? renderHeadRows(headMyGradingElements, showHideSettings)
                  : ""
                : permissionsData?.elearning_grading_team_grading
                ? renderHeadRows(headTeamGradingElements, showHideSettings)
                : ""}
            </TableRow>
          </TableHead>
          <TableBody className={"tableBodyContainer"}>
            {selectedTab === "my" ? (
              permissionsData?.elearning_grading_my_grading ? (
                !isProgressLoading && ProgressData && renderRows(ProgressData)
              ) : (
                <NoPermission />
              )
            ) : permissionsData?.elearning_grading_team_grading ? (
              !isTeamGradingLoading &&
              teamGradingPayload &&
              teamGradingPayload.result &&
              renderRows(teamGradingPayload.result)
            ) : (
              <NoPermission />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(selectedTab === "my" && isProgressLoading) ||
      (selectedTab === "team" && isTeamGradingLoading) ? (
        <div className={"loadContainer"}>
          <MobileLoader />
        </div>
      ) : null}
      {((selectedTab === "my" &&
        !isProgressLoading &&
        (!ProgressData || ProgressData.length === 0)) ||
        (selectedTab === "team" &&
          !isTeamGradingLoading &&
          (!teamGradingPayload ||
            !teamGradingPayload.result ||
            teamGradingPayload.result.length === 0))) && (
        <Typography className="noRecordText">No record</Typography>
      )}
      <div className={"paginationContainer"}>
        <Stack spacing={2}>
          <Pagination
            size="small"
            color="primary"
            count={
              selectedTab === "my"
                ? myGradingTotalPages
                : teamGradingPayload.totalPages
            }
            variant="outlined"
            page={
              selectedTab === "my" ? myGradingPageNumber : teamGradingPageNumber
            }
            onChange={
              selectedTab === "my"
                ? handleMyGradingPageChange
                : handleTeamPageChange
            }
          />
        </Stack>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teamGradingPayload: state.eLearningGrading.teamGradingPayload,
  isTeamGradingLoading: state.eLearningGrading.isTeamGradingLoading,
  ProgressData: state.elearningProgress.ProgressData,
  isProgressLoading: state.elearningProgress.isProgressLoading,
  myGradingTotalPages: state.elearningProgress.totalPage,
  // filters
  // role
  // isRolesLoading: state.eLearningGrading.isRolesLoading,
  // roleDetails: state.eLearningGrading.roleDetails,
  // lesson data
  myLessonNamesData: state.elearningProgress.myLessonNamesData,
  isMyLessonNamesDataLoading:
    state.elearningProgress.isMyLessonNamesDataLoading,
  permissionsData: state.settingsRoles.permissionsData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchRoleData,
  fetchData,
  fetchTeamGradingData,
  getMyLessonNames,
})(MobileGrading);
export default React.memo(ConnectWith);
